import React, { Fragment } from 'react'
import { Modal, ModalBody, ModalHeader, Col, Table } from 'reactstrap'

export default function Element({ onChange, showModal, dataGrid, index }) {
  function onModal() {
    onChange()
  }

  return (
    <Modal isOpen={showModal} toggle={onModal} style={{ width: '100%' }}>
      <ModalHeader toggle={onModal}>Log de importação de pedidos</ModalHeader>
      <ModalBody>
        <Col md={12}>
          {dataGrid === undefined ? (
            <Fragment />
          ) : (
            <Col className="tb-report position-relative table-responsive">
              <Table striped className="mb-4">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  {dataGrid.map((d, index) => (
                    <tr key={index}>
                      <td>Erro</td>
                      <td>{d.message}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </Col>
      </ModalBody>
    </Modal>
  )
}
