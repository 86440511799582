import React, { useEffect, useState } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, FormGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import Dropzone from 'react-dropzone'
import { formatSizeUnits } from '../../../../helpers/functions'
import { DropContainer } from './styles'
import { uniqueId } from 'lodash'
import axios from 'axios'
import File from './File'

export default function Element({ onChange, showModal, sysArquivoRefId }) {
  const [files, setFiles] = useState([])
  library.add(fab, faSpinner)

  async function processUpload(ev) {
    var formdata = new FormData()
    formdata.append('SysArquivoRefId', sysArquivoRefId)
    formdata.append('Processo', 'AdminUpload')
    formdata.append('Tipo', ev.tipo)

    formdata.append('MimeType', ev.file.type)
    formdata.append('File', ev.file, ev.file.name)
    formdata.append('Nome', ev.file.name)

    const api = axios.create({ baseURL: process.env.REACT_APP_API })
    await api
      .post('/SysArquivo/AddOrUpdate', formdata, {
        onUploadProgress: (e) => {
          // const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          //updateFile(ev.id, { progress })
        },
      })
      .then((response) => {
        onChange(true)
        //updateFile(ev.id, { uploaded: true, id: response.data.id, url: response.data.url })
      })
      .catch(() => {
        //updateFile(ev.id, { error: true })
      })
  }

  async function handleDrop(ev) {
    let itens = [...files]
    let tipo = ''
    ev.forEach((file) => {
      const isExist = itens.some((f) => f.name === file.name)
      if (!isExist) {
        for (let index = 1; index < file.name.length; index++) {
          if (file.name.substring(file.name.length - index, file.name.length - index + 1) === '.') {
            tipo = file.name.substring(file.name.length - index + 1, file.name.length)
            break
          }
        }

        itens.push({
          file,
          id: uniqueId(),
          name: file.name,
          readableSize: formatSizeUnits(file.size),
          progress: 0,
          uploaded: false,
          error: false,
          url: null,
          tipo,
        })
      }
    })
    setFiles(itens)

    for (const item of itens) {
      await processUpload(item)
    }
  }

  useEffect(() => {
    if (showModal === true) {
      setFiles([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  function onModal() {
    onChange(false)
  }

  return (
    <Modal isOpen={showModal} toggle={onModal}>
      <ModalHeader toggle={onModal}> Adicionar documentos </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <DropContainer {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Arraste e solte os arquivos ou clique para selecionar</p>
                  </DropContainer>
                )}
              </Dropzone>
            </FormGroup>
          </Col>

          <Col md={12}>{!!files.length && files.map((file) => <File key={file.id} values={file} />)}</Col>

          <Col md={12} className="d-block text-right">
            <Button type="button" onClick={onModal} className="btn-wide btn-lg" color="danger" style={{ marginLeft: '10px' }}>
              Fechar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
