import React, { Fragment } from 'react'
import { PageTitle, ReactCSSTransitionGroup, i18n } from '../../../Components'

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import CardOrdens from './Orders'
import CardFolders from '../Folders'

export default function Componente() {
  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('home.heading')} subheading={i18n.t('home.admin.subheading')} icon="BsGridFill" />
          <div className="page-content">
            <Row>
              <Col lg="6">
                <Card className="main-card mb-2">
                  <CardBody>
                    <CardTitle>{i18n.t('home.admin.order.title')} </CardTitle>
                    <CardOrdens />
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="main-card mb-2">
                  <CardBody>
                    <CardTitle>{i18n.t('home.files.title')}</CardTitle>
                    <CardFolders />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
