/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react'
import { toast, Bounce, i18n } from '../../../../Components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalBody, ModalHeader, Row, Col, FormGroup, Label, Input, Table } from 'reactstrap'

import { ParticipantesGet, AddLinkConta } from '../../../../Service/ApiService'
import { MdAddLink } from 'react-icons/md'

export default function Element({ change, showModal, contaId }) {
  library.add(fab, faSpinner)
  const [values, setValues] = useState({ nome: '' })
  const [dataGrid, setDataGrid] = useState()

  async function loadGrid() {
    var response = await ParticipantesGet({ contaId: 0, ativo: true, nomeFantasia: values.nome })
    setDataGrid(response)
  }

  function onModal() {
    change()
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    if (values.nome.length < 3) {
      setDataGrid()
      return
    }
    loadGrid()
  }, [values.nome])

  useEffect(() => {
    if (showModal === true) setValues({ nome: '' })
  }, [showModal])

  async function onLink(id) {
    var response = await AddLinkConta({ participanteId: id, contaId })
    if (response.result) {
      change()
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <Modal isOpen={showModal} toggle={onModal} size="lg" style={{ width: '100%' }}>
      <ModalHeader toggle={onModal}>{i18n.t('account.edit.customers.modal.title')}</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> {i18n.t('account.edit.customers.modal.fieldName')}</Label>
              <Input type="text" onChange={onChange} value={values.nome} name="nome" id="nome" />
            </FormGroup>
          </Col>

          <Col md={12}>
            {dataGrid === undefined ? (
              <Fragment />
            ) : (
              <Col className="tb-report position-relative table-responsive">
                <Table striped className="mb-4">
                  <thead>
                    <tr>
                      <th>{i18n.t('account.edit.customers.columns.custId')}</th>
                      <th>{i18n.t('account.edit.customers.columns.nmAccount')}</th>
                      <th>{i18n.t('account.edit.customers.columns.name')}</th>
                      <th>{i18n.t('account.edit.customers.columns.address')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataGrid.map((d) => (
                      <tr
                        key={d.custId}
                        style={{
                          color: d.nmConta !== null ? '#e55353' : '',
                        }}
                      >
                        <td>{d.custId}</td>
                        <td>{d.nmConta}</td>
                        <td>{d.nomeFantasia}</td>
                        <td>{d.endereco}</td>
                        <td style={{ textAlign: 'center' }}>
                          <button className="btn btn-primary btn-sm" onClick={() => onLink(d.participanteId)}>
                            <MdAddLink size={16} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
