import styled from 'styled-components'

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  text-align: center;
  padding: 30px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #dfe8ec;
  border-radius: 4px;
  margin-bottom: 20px;
  color: hsl(0, 0%, 50%);
  cursor: pointer;

  > p {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const Container = styled.div`
  border: 2px solid var(--gray);
  border-radius: 4px;

  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;

  :hover {
    background-color: var(--secondary);

    cursor: pointer;
  }
`
export const CardIcon = styled.div`
  width: 40px !important;
  margin: 0 !important;
`

export const CardName = styled.div`
  padding-left: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
