import React, { Fragment } from 'react'
import { Container, CardIcon, CardName } from './styles'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { MdCheckCircle } from 'react-icons/md'

function Component({ values, onChange }) {
  return (
    <Fragment>
      <Container>
        <CardIcon>
          <FileIcon extension={values.tipo} {...defaultStyles[values.tipo]} />
        </CardIcon>

        <CardName>{values.name}</CardName>
        <MdCheckCircle size={24} color="var(--success)" />
      </Container>
    </Fragment>
  )
}

export default Component
