import React, { Fragment, useState, useEffect } from 'react'
import { Select, PageTitle, ReactCSSTransitionGroup, useNavigate, toast, Bounce, i18n, useParams } from '../../../Components'
import { Card, CardBody, Row, Col, CustomInput, FormGroup, Label, Input, Button } from 'reactstrap'
import { ContaAdd, SegmentoGet } from '../../../Service/ApiService'

const initValues = { nome: '', situacao: '', nomeUsuario: '', email: '', perfilId: '3', segmentos: [], tpVenda: '' }

export default function FormCadastro() {
  const { id } = useParams()
  let navigate = useNavigate()
  const [values, setValues] = useState({ ...initValues, pasta: id })

  const [segmentos, setSegmentos] = useState()

  async function loadSegmentos() {
    const response = await SegmentoGet({ ativo: true })
    let items = []
    response.forEach((item) => {
      items.push({ value: item.segmentoId, label: item.nome })
    })
    setSegmentos(items)
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(name, ev) {
    setValues({ ...values, [name]: ev })
  }

  useEffect(() => {
    loadSegmentos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.nome || !values.situacao || !values.nomeUsuario || !values.email || !values.segmentos) {
      toast(i18n.t('account.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    let _values = { ...values }
    _values.segmentos = []
    if (_values.segmentos) {
      values.segmentos.forEach((element) => {
        _values.segmentos.push(element.value)
      })
    }

    const response = await ContaAdd(_values)
    if (response.result) {
      window.location.href = '/#/admin/Account/Query'
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('account.add.heading')} subheading={i18n.t('account.add.subheading')} />
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label>{i18n.t('account.add.form.name')} </Label>
                      <Input type="text" onChange={onChange} name="nome" id="nome" />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <Label>{i18n.t('account.add.form.typeSale.title')}</Label>
                    <CustomInput type="select" onChange={onChange} id="tpVenda" name="tpVenda">
                      <option value=""></option>
                      <option value="D">{i18n.t('account.add.form.typeSale.direct')}</option>
                      <option value="I">{i18n.t('account.add.form.typeSale.indirect')}</option>
                    </CustomInput>
                  </Col>
                  <Col md={2}>
                    <Label>{i18n.t('account.add.form.status.title')}</Label>
                    <CustomInput type="select" onChange={onChange} id="situacao" name="situacao">
                      <option value=""></option>
                      <option value="A">{i18n.t('account.add.form.status.ative')}</option>
                      <option value="N">{i18n.t('account.add.form.status.underReview')}</option>
                      <option value="I">{i18n.t('account.add.form.status.inactive')}</option>
                    </CustomInput>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label> {i18n.t('account.edit.form.segment')} *</Label>
                      <Select
                        placeholder="Selecione"
                        noOptionsMessage={() => 'Nenhuma opção disponível'}
                        onChange={(ev) => {
                          onChangeCat('segmentos', ev)
                        }}
                        value={values.segmentos}
                        isMulti
                        isClearable
                        name="segmentos"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={segmentos}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      <Label>{i18n.t('account.add.form.userName')} </Label>
                      <Input type="text" onChange={onChange} name="nomeUsuario" id="nomeUsuario" />
                    </FormGroup>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <Label>{i18n.t('account.add.form.email')}</Label>
                      <Input type="text" onChange={onChange} name="email" id="email" />
                    </FormGroup>
                  </Col>

                  <Col md={12} className="d-block text-right">
                    <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                      {i18n.t('layout.btnGoBack')}
                    </Button>

                    <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                      {i18n.t('account.add.form.buttonSave')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
