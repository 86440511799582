import React, { Fragment } from 'react'
import { i18n } from '../../../Components'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { CardTitle, Col, Row, Table } from 'reactstrap'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 2, border: '1px solid #e9ecef', background: 'white' }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export default function ScrollableTabsButtonAuto({ values }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Fragment>
      <CardTitle style={{ display: 'flex', justifyContent: 'center', marginBottom: '0' }}>
        <span>Remessas</span>
      </CardTitle>
      {values?.length === 0 || values === undefined ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
          <h2>Aguardando remessas</h2>
        </div>
      ) : (
        <Box sx={{ bgcolor: '#e9ecef', marginBottom: '10px' }}>
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
            {values.map((item, index) => (
              <Tab key={`Tabs-${item.ordRemessaId}`} label={item.ordRemessaIdExt} {...a11yProps({ index })} />
            ))}
          </Tabs>
          {values.map((item, index) => (
            <TabPanel key={`TabPanel-${item.ordRemessaId}`} value={value} index={index}>
              <Row>
                <Col md={12} className="tb-report position-relative table-responsive">
                  <Table striped className="mb-4">
                    <thead>
                      <tr>
                        <th>{i18n.t('order.itens.grid.materialId')} </th>
                        <th>{i18n.t('order.itens.grid.materialName')}</th>
                        <th style={{ textAlign: 'right' }}>Qtde</th>
                        <th style={{ textAlign: 'center' }}>Lote</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.itens.map((p) => (
                        <tr key={`Rem-${p.ordRemItemId}`}>
                          <td>{p.materialIdExt}</td>
                          <td>{p.nmMaterial}</td>
                          <td style={{ textAlign: 'right' }}>{p.qtde}</td>
                          <td style={{ textAlign: 'center' }}>{p.loteExt}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <CardTitle style={{ marginBottom: '0.5rem' }}>
                    <span>Remessa</span>
                  </CardTitle>
                  <Row>
                    <Col md={12}>
                      Número:<strong>{item.ordRemessaIdExt}</strong>
                    </Col>
                    <Col md={12}>
                      Data:<strong>{item.dhRemessa}</strong>
                    </Col>
                  </Row>
                </Col>

                <Col md={4}>
                  <CardTitle style={{ marginBottom: '0.5rem' }}>
                    <span>Fatura</span>
                  </CardTitle>
                  <Row>
                    <Col md={12}>
                      Número: <strong>{item.nrFatura}</strong>
                    </Col>
                    <Col md={12}>
                      Data: <strong>{item.dhFatura}</strong>
                    </Col>
                  </Row>
                </Col>

                <Col md={4}>
                  <CardTitle style={{ marginBottom: '0.5rem' }}>
                    <span>Nota fiscal</span>
                  </CardTitle>
                  <Row>
                    <Col md={12}>
                      Número: <strong>{item.nrNotaFiscal}</strong>
                    </Col>
                    <Col md={12}>
                      Data:<strong>{item.dhNotaFiscal}</strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPanel>
          ))}
        </Box>
      )}
    </Fragment>
  )
}
