import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

function InputComponent({ value, onChange, ...inputProps }) {
  const [state, setstate] = useState(value)

  useEffect(() => {
    if (value === null || value === undefined || value === 0) setstate('')
    else setstate(Mask(value.toLocaleString(navigator.language)))
  }, [value])

  const onInput = (ev) => {
    var valMasked = Mask(ev.target.value)
    setstate(valMasked)

    if (onChange) {
      onChange({
        target: {
          value: valMasked?.replace('.', '').replace(',', '.'),
          ...inputProps,
        },
      })
    }
  }

  const Mask = (value) => {
    if (value === undefined || value === 0) return
    value = value.replace(/\D/g, '')

    return Number(value.replace('.', '')).toLocaleString(navigator.language)
  }

  return <Input onChange={onInput} type="text" value={state} {...inputProps} />
}

export default InputComponent
