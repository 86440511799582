import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { i18n, toast, Bounce } from '../../Components'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { FaqAddOrUpdate, FaqGetById } from '../../Service/ApiService'

const initValues = {
  faqId: 0,
  titulo: '',
  conteudo: '',
  situacao: '',
}
const configEditorHtml = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'code-block', { header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link', 'image', 'video'],
  ],
}

export default function Element({ onChange, showModal, faqId }) {
  const [values, setValues] = useState(initValues)

  function onModal() {
    onChange()
  }
  function onChangeForm(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onEditorHtml(html) {
    setValues({ ...values, conteudo: html })
  }

  async function onSubmit() {
    if (!values.titulo || !values.conteudo || !values.situacao) {
      toast('Preencha os campos obrigatórios', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    const response = await FaqAddOrUpdate(values)
    if (response)
      if (response.result) {
        toast(response.message, { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        onModal()
      } else {
        toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
  }

  async function loadValues() {
    setValues()
    if (faqId) {
      const response = await FaqGetById(faqId)

      if (response) setValues(response)
    } else setValues(initValues)
  }

  useEffect(() => {
    if (showModal) loadValues()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  return (
    <Fragment>
      {values !== undefined && (
        <Modal isOpen={showModal} toggle={onModal} size="lg" style={{ width: '100%' }}>
          <ModalHeader toggle={onModal}> {!faqId ? 'Adicionar' : 'Editar'} pergunta e resposta</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={10}>
                <FormGroup>
                  <Label> Título (Pergunta) * </Label>
                  <Input type="text" onChange={onChangeForm} value={values.titulo} name="titulo" id="titulo" />
                </FormGroup>
              </Col>
              <Col md={2}>
                <Label> Situação *</Label>
                <CustomInput type="select" onChange={onChangeForm} value={values.situacao} id="situacao" name="situacao">
                  <option value=""></option>
                  <option value="R">Rascunho</option>
                  <option value="P">Publicado</option>
                  <option value="I">Inativo</option>
                </CustomInput>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label>Resposta *</Label>
                  <ReactQuill modules={configEditorHtml} theme="snow" onChange={onEditorHtml} value={values.conteudo} name="conteudo" id="conteudo" />
                </FormGroup>
              </Col>

              <Col md={12} className="d-block text-right">
                <Button type="button" onClick={onModal} className="btn-wide mr-2 btn-lg" color="secondary">
                  {i18n.t('layout.btnGoBack')}
                </Button>
                <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                  {i18n.t('user.add.form.buttonSave')}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  )
}
