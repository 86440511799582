import React from 'react'
import { ApexCharts } from '../../../../Components'
import { Card, CardHeader } from 'reactstrap'
import { MaskReal, abbreviateNumber, normaliseValue } from '../../../../helpers/functions'
import { replace } from 'lodash'

export default function FormCadastro({ records }) {
  const options = {
    chart: {
      width: 480,
      type: 'pie',
    },
    plotOptions: {
      pie: {
        offsetX: -60,
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val
              },
            },
            value: {
              show: true,
              showAlways: false,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return 'R$ ' + normaliseValue(replace(val, 'R$ ', ''))
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: 'Total',
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                return MaskReal(
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                )
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return normaliseValue(val) + '%'
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return abbreviateNumber(val)
        },
      },
    },
    labels: records.labels,
    legend: {
      show: true,

      floating: true,
      markers: {
        size: 0,
      },

      formatter: function (seriesName, opts) {
        return `${seriesName} : R$ ${abbreviateNumber(records.series[opts.seriesIndex])}`
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }

  return (
    <Card className="main-card mb-2">
      <CardHeader className="card-header-tab">Vendas por categorias</CardHeader>

      <ApexCharts options={options} series={records.series} type="donut" height={350} />

      {/* <CardBody>
          <MsgSemRegistros />
        </CardBody> */}
    </Card>
  )
}
