import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import FormUser from '../../../User/form'

import { i18n } from '../../../../Components'

export default function Element({ onChange, showModal, usuarioId, contaId }) {
  library.add(fab, faSpinner)

  function onModal() {
    onChange()
  }

  return (
    <Modal isOpen={showModal} toggle={onModal} size="lg">
      <ModalHeader toggle={onModal}> {!usuarioId ? i18n.t('account.edit.users.modal.title.add') : i18n.t('account.edit.users.modal.title.edit')}</ModalHeader>
      <ModalBody>
        <FormUser usuarioId={usuarioId} contaId={contaId} change={onModal} />
      </ModalBody>
    </Modal>
  )
}
