import React, { Fragment, useState, useEffect } from 'react'
import { Row } from 'reactstrap'

import { useDispatch } from '../../../Components'
import { SysArquivosGet } from '../../../Service/ApiService'
import Folder from './Folder'
import { getUser } from '../../../Reducers/storageApp'

function FormConsulta() {
  const { contaId } = getUser()
  const dispatch = useDispatch()
  const [folders, setFolders] = useState()

  async function loadFolders() {
    var filters = { ativo: true, contaId, publicos: true, onlyFolder: true, SysArquivoRefId: 0 }
    const response = await SysArquivosGet(filters)
    setFolders(response)
  }

  useEffect(() => {
    loadFolders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onFolder(ev) {
    dispatch({ type: 'FolderOpen', value: ev })
    window.location.href = `/#/admin/Document/Query`
  }

  return (
    <Fragment>
      {folders && (
        <Row>
          {folders.map((folder, index) => (
            <Folder onChange={onFolder} key={index} folder={folder} cardSize={6} />
          ))}
        </Row>
      )}
    </Fragment>
  )
}

export default FormConsulta
