/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalBody, ModalHeader, Col, Table } from 'reactstrap'
import { i18n } from '../../translate/i18n'
import { MdAddLink } from 'react-icons/md'

export default function Element({ onChange, showModal, lotes, index }) {
  library.add(fab, faSpinner)

  function onModal() {
    onChange()
  }

  return (
    <Modal isOpen={showModal} size="lg" toggle={onModal} style={{ width: '100%' }}>
      <ModalHeader toggle={onModal}>{i18n.t('order.itens.lot.title')}</ModalHeader>
      <ModalBody>
        <Col md={12}>
          {lotes === undefined ? (
            <Fragment />
          ) : (
            <Col className="tb-report position-relative table-responsive">
              <Table striped className="mb-4">
                <thead>
                  <tr>
                    <th>{i18n.t('order.itens.lot.columns.nrLot')}</th>
                    <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.lot.columns.orderLimit')}</th>
                    <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.lot.columns.availability')}</th>
                    <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.lot.columns.due')}</th>
                  </tr>
                </thead>
                <tbody>
                  {lotes.map((d) => (
                    <tr key={d.loteId}>
                      <td>{d.nrLote}</td>
                      <td style={{ textAlign: 'center' }}>{d.dtLimitePedido}</td>
                      <td style={{ textAlign: 'center' }}>{d.dtDisEstimada}</td>
                      <td style={{ textAlign: 'center' }}>{d.dtVencimento}</td>
                      <td style={{ textAlign: 'right' }}>
                        <button className="btn btn-primary btn-sm" onClick={() => onChange({ loteId: d.loteId, nrLote: d.nrLote, dtPrevEntrega: d.dtDisEstimada, index })}>
                          <MdAddLink size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </Col>
      </ModalBody>
    </Modal>
  )
}
