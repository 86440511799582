import React, { Fragment, useState } from 'react'
import { FiFilePlus } from 'react-icons/fi'
import { Button } from 'reactstrap'
import ModalAdd from './Modal'

function Component({ sysArquivoRefId, onChange }) {
  const [confModal, setConfModal] = useState({ showModal: false })

  function onModal() {
    setConfModal({ ...confModal, showModal: !confModal.showModal })
    onChange()
  }

  function onClickAdd() {
    setConfModal({ ...confModal, showModal: true })
  }

  return (
    <Fragment>
      <ModalAdd onChange={onModal} showModal={confModal.showModal} sysArquivoRefId={sysArquivoRefId} />

      <Button type="button" onClick={onClickAdd} className="btn-pill" color="success" style={{ marginRight: '15px' }}>
        <FiFilePlus size={18} /> Arquivo
      </Button>
    </Fragment>
  )
}

export default Component
