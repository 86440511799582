import React, { useState } from 'react'

import { Button, Modal, ModalBody } from 'reactstrap'
import { BoxTermo, ContainerForm, Title } from './styles'
import { AceiteTermos } from '../../../../Service/ApiService'
import { toast, Bounce, i18n, Loading } from '../../../../Components'

export default function Element({ usuarioId, change }) {
  const [isLoading, setIsLoading] = useState(false)
  const [language] = useState(localStorage.getItem('i18nextLng'))
  async function onConcordo() {
    setIsLoading(true)
    var response = await AceiteTermos(usuarioId)
    if (response?.result === true) change()
    else {
      toast('Ocorreu um problema ao salvar a sua confirmação', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <>
      <Loading isVisible={isLoading} />

      <Modal style={{ margin: 'auto' }} isOpen={true}>
        <ModalBody>
          <ContainerForm>
            <Title>{i18n.t('login.signIn.titleTermToAccept')}</Title>
            {
              {
                'pt-BR': (
                  <BoxTermo id="boxTermo">
                    Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços bem como gerar estatísticas de visitação. Ao utilizar nossos serviços, você concorda com tal monitoramento.
                    <br></br>
                    <br></br>
                    <a target="_blank" href={`https://www.sysmex.com/la/pt/Documents/Sysmex_Latam__Privacy_Policy_PORTUGUESE.pdf`} rel="noreferrer">
                      Conheça nossa Política de Privacidade.
                    </a>
                  </BoxTermo>
                ),
                en: (
                  <BoxTermo id="boxTermo">
                    We use cookies and other similar technologies to enhance your experience on our services as well as to generate visitation statistics. By using our services, you agree to such monitoring.
                    <br></br>
                    <br></br>
                    <a target="_blank" href={`https://www.sysmex.co.jp/en/privacy.html`} rel="noreferrer">
                      Read our privacy policy.
                    </a>
                  </BoxTermo>
                ),
                es: (
                  <BoxTermo id="boxTermo">
                    Utilizamos cookies y otras tecnologías similares para mejorar su experiencia en nuestros servicios y generar estadísticas de visitas. Al utilizar nuestros servicios, usted acepta dicho monitoreo.
                    <br></br>
                    <br></br>
                    <a target="_blank" href={`https://www.sysmex.com/la/es/Documents/Sysmex_Latam__Privacy_Policy_SPANISH.pdf`} rel="noreferrer">
                      Lea nuestra política de privacidad.
                    </a>
                  </BoxTermo>
                ),
              }[language]
            }

            <Button className="btn-wide btn-lg" color="success" type="button" onClick={onConcordo}>
              {i18n.t('login.signIn.buttonTermToAccept')}
            </Button>
          </ContainerForm>
        </ModalBody>
      </Modal>
    </>
  )
}
