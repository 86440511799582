import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'

import { SysParametroAddOrUpdate, SysParametroGet } from '../../Service/ApiService'
import { PageTitle, ReactCSSTransitionGroup, useNavigate, toast, Bounce, i18n, InputNumber, Tooltip } from '../../Components'

const initValues = {
  urlBasePainel: '',
  diasAvisoFechaLote: '',
  urlEnvioOrdemSAP: '',
  eMailsLogErros: '',
}

export default function FormCadastro() {
  const navigate = useNavigate()
  const [values, setValues] = useState(initValues)

  async function loadValues() {
    const response = await SysParametroGet()
    if (response) setValues(response)
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.urlBasePainel || !values.diasAvisoFechaLote || !values.urlEnvioOrdemSAP) {
      toast(i18n.t('tpOrder.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    const response = await SysParametroAddOrUpdate(values)
    if (response.result) {
      window.location.href = '/#/admin'
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Parâmetros" subheading="Parâmetros e configurações do portal" />
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={10}>
                    <FormGroup>
                      <Label>
                        URL base do portal *
                        <Tooltip placement="auto" text="?" content="Essa url é utilizada na composição dos link enviados por e-mail" key="urlBasePainel" id="urlBasePainel" />
                      </Label>
                      <Input type="text" onChange={onChange} name="urlBasePainel" id="urlBasePainel" value={values.urlBasePainel} />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>
                        Dias fechamento de lote *
                        <Tooltip className="tooltip" placement="auto" text="?" content="Quantidade de dias que os lotes em vencimento devem ser listados na pagina inicial" key="diasAvisoFechaLote" id="diasAvisoFechaLote" />
                      </Label>
                      <InputNumber onChange={onChange} name="diasAvisoFechaLote" id="diasAvisoFechaLote" value={values.diasAvisoFechaLote} />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label>
                        URL endpoint envio ordens SAP *
                        <Tooltip className="tooltip" placement="auto" text="?" content="Url do endpoint da api SAP para envio das ordens" key="urlEnvioOrdemSAP" id="urlEnvioOrdemSAP" />
                      </Label>
                      <Input type="text" onChange={onChange} name="urlEnvioOrdemSAP" id="urlEnvioOrdemSAP" value={values.urlEnvioOrdemSAP} />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Endereços de e-mail para envio de logs de erros (quando for mais de um, separar por ;)</Label>
                      <Input type="text" onChange={onChange} name="eMailsLogErros" id="eMailsLogErros" value={values.eMailsLogErros} />
                    </FormGroup>
                  </Col>

                  <Col md={12} className="d-block text-right">
                    <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                      {i18n.t('layout.btnGoBack')}
                    </Button>
                    <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                      {i18n.t('tpOrder.add.form.buttonSave')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
