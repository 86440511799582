import React from 'react'
import './Assets/layout.css'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import storeApp from './Reducers/storeApp'
import './Assets/base.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Main from './Layout/Main'

const rootElement = document.getElementById('root')

//"dev": "react-scripts --openssl-legacy-provider start",

ReactDOM.render(
  <div>
    <Provider store={storeApp}>
      <Main />
    </Provider>
  </div>,
  rootElement
)
