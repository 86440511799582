export { useNavigate } from 'react-router-dom'
export { useParams } from 'react-router-dom'

export { default as ReactCSSTransitionGroup } from 'react-addons-css-transition-group'

export { toast } from 'react-toastify'
export { Bounce } from 'react-toastify'
export { useSelector, useDispatch } from 'react-redux'
export { default as Tabs } from 'react-responsive-tabs'
export { default as moment } from 'moment'

export { i18n } from '../translate/i18n'

export { default as InputCheck } from './InputCheck'
export { default as InputCnpjCpf } from './InputCnpjCpf'
export { default as InputRadio } from './InputRadio'
export { default as InputTelefone } from './InputTelefone'
export { default as InputReal } from './InputReal'
export { default as InputNumber } from './InputNumber'

export { default as Select } from 'react-select'
export { default as AsyncSelect } from 'react-select/async'

export const SelectGroupLabel = (data) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <span style={{ fontSize: '0.8rem', fontWeight: 'bold', color: '#172B4D' }}>{data.label}</span>
    <span style={{ fontSize: '0.8rem' }} className="badge badge-pill badge-secondary">
      {data.options.length}
    </span>
  </div>
)

export { default as ReactDatatable } from '@ashvin27/react-datatable'
export { default as Loading } from './Loading'
export { default as Tooltip } from './Tooltip'

export { default as PageTitle } from '../Layout/AppMain/PageTitle'

export { default as NotFound } from './NotFound'
export { default as MsgSemRegistros } from './MsgSemRegistros'

export { default as ApexCharts } from 'react-apexcharts'
