import React, { useEffect, useState } from 'react'
import { toast, Bounce, i18n, Tooltip, useParams } from '../../../Components'

import { AltSenha, GetTokenApp, IsHashValido } from '../../../Service/ApiService'
import { ContainerForm, Input, Button, LbPassword } from '../styles'

export default function Element() {
  const { id } = useParams()
  const [values, setValues] = useState({ password: '', confirmPassword: '', hash: '' })
  const [TokenApp, setTokenApp] = useState()
  const regPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()+,-/:;<=>?@[\]^_`{|}~])(?:([0-9a-zA-Z!"#$%&'()+,-/:;<=>?@[\]^_`{|}~])){6,}$/

  async function getTokenApp() {
    var token = TokenApp
    if (!TokenApp) {
      var response = await GetTokenApp()
      if (response?.token) {
        token = response.token
        setTokenApp(response.token)
      } else {
        toast(i18n.t('login.signIn.MSG_ErrorGetTokenApp'), { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    }
    return token
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  async function onSubmit(ev) {
    ev.preventDefault()

    if (values.password.length < 6) {
      toast(i18n.t('login.newPass.MSG_ErrorPassValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (!regPass.test(values.password)) {
      toast(i18n.t('login.newPass.MSG_ValidateSecurity'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    if (values.confirmPassword !== values.password) {
      toast(i18n.t('login.newPass.MSG_ErrorPassCompare'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    let encoded = btoa(values.password)

    var token = await getTokenApp()
    if (token) {
      try {
        const req = await AltSenha(token, { senha: encoded, hash: values.hash })
        if (req?.result) {
          toast(i18n.t('login.newPass.MSG_Success'), { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        } else {
          toast(req.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        }
      } catch (error) {
        toast(`${error}`, { type: 'error', transition: Bounce, closeButton: true, autoClose: 500, position: 'top-right' })
      }

      setTimeout(function () {
        window.location.href = '/#/Login'
      }, 5000)
    }
  }

  async function validaHash(hash) {
    var token = await getTokenApp()
    if (token) {
      const result = await IsHashValido(token, hash)
      if (!result?.result) {
        toast(result.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        setTimeout(function () {
          window.location.href = '/#/Login'
        }, 5000)
      } else {
        setValues({ ...values, hash: hash })
      }
    }
  }

  useEffect(() => {
    if (id) validaHash(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <ContainerForm>
      <Input type="password" name="password" placeholder={i18n.t('login.newPass.pass')} onChange={onChange} value={values.password} />
      {!regPass.test(values.password) && values.password && (
        <LbPassword>
          {i18n.t('login.newPass.validatedPassword')}
          <Tooltip placement="auto" text="?" content={i18n.t('login.newPass.validatedPasswordH')} key="validatedPassword" id="validatedPassword" />
        </LbPassword>
      )}

      <Input type="password" name="confirmPassword" placeholder={i18n.t('login.newPass.passConfirmation')} onChange={onChange} value={values.confirmPassword} />
      <Button onClick={onSubmit}> {i18n.t('login.newPass.buttonSubmit')} </Button>
    </ContainerForm>
  )
}
