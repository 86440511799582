import React, { Fragment, useState } from 'react'
import { Container, CardIcon, CardName, Wrapper } from './styles'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { SysArquivoDelete } from '../../../../Service/ApiService'
import { toast, Bounce, moment } from '../../../../Components'
import { formatSizeUnits } from '../../../../helpers/functions'
import { BsTrashFill } from 'react-icons/bs'

function Component({ values, editAccess }) {
  const [isDeleted, setIsDeleted] = useState(false)

  async function onOpen() {
    window.location.href = `${process.env.REACT_APP_API}/SysArquivo/GetSysArquivo?id=${values.sysArquivoId}`
  }

  async function onDeleteFile() {
    const response = await SysArquivoDelete(values.sysArquivoId)
    if (response.result) {
      setIsDeleted(true)
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <Fragment>
      {!isDeleted && (
        <Wrapper>
          <td>
            <Container onClick={onOpen}>
              <CardIcon>
                <FileIcon extension={values.extensao} {...defaultStyles[values.extensao]} />
              </CardIcon>
              <CardName>{values.nome}</CardName>
            </Container>
          </td>
          <td style={{ margin: '0 8px', textAlign: 'right' }}>{formatSizeUnits(values.tamanho)}</td>
          <td style={{ margin: '0 20px', textAlign: 'center', whiteSpace: 'nowrap' }}>{moment(values.dhUpload).format('DD/MM/YY HH:mm')}</td>
          <td style={{ margin: '0 4px' }}>
            {editAccess && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <button className="btn btn-info btn-sm btn-danger" onClick={onDeleteFile}>
                  <BsTrashFill size={14} />
                </button>
              </div>
            )}
          </td>
        </Wrapper>
      )}
    </Fragment>
  )
}

export default Component
