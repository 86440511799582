import React, { Suspense, Fragment } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import AppMain from './Main'
import { ToastContainer } from 'react-toastify'
import Login from '../../Pages/Login'
import { NotFound } from '../../Components'
import { getToken } from '../../Reducers/storageApp'

const PrivateRoute = ({ component: Component }) => {
  const token = getToken()
  if (!token) return (window.location.href = '/#/Login')
  return Component
}

export default function App() {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">
                Aguarde enquanto carregamos todos os componentes
                <small> ... </small>
              </h6>
            </div>
          </div>
        }
      >
        <HashRouter>
          <Routes>
            <Route path="/admin/*" element={<PrivateRoute component={<AppMain />} />} />
            <Route exact path="/" element={<PrivateRoute component={<Navigate to="/Login" />} />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </HashRouter>
      </Suspense>

      <ToastContainer />
    </Fragment>
  )
}
