import React, { Fragment, useState } from 'react'
import { useNavigate, PageTitle, ReactCSSTransitionGroup, toast, Bounce, InputTelefone } from '../../Components'
import { Row, Col, FormGroup, CustomInput, Label, Input, Button, Card, CardBody } from 'reactstrap'
import { SubTitle, ItemContact, LinkContact, TimeContact, AddressContact } from './styles'
import { BsFillTelephoneFill, BsFillClockFill } from 'react-icons/bs'
import { ParticipanteContato } from '../../Service/ApiService'
import { MaskPhone } from '../../helpers/functions'
const initValues = {
  assunto: '',
  telefone: '',
  mensagem: '',
}

export default function FormCadastro() {
  const [values, setValues] = useState(initValues)
  const navigate = useNavigate()

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit() {
    if (!values.assunto || !values.telefone || !values.mensagem) {
      toast('Preencha os campos obrigatórios', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    const response = await ParticipanteContato({ ...values, telefone: MaskPhone(values.telefone) })
    if (response)
      if (response.result) {
        toast('Mensagem enviada com sucesso', { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        setTimeout(function () {
          window.location.href = '/#/admin'
        }, 5000)
      } else {
        toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Fale conosco" subheading="Dúvidas, elogios, sugestões e reclamações" icon="BsHeadset" />
          <div className="page-content">
            <Row>
              <Col md={8}>
                <Card className="main-card mb-2">
                  <CardBody>
                    <Row>
                      <Col md={9}>
                        <FormGroup>
                          <Label> Assunto * </Label>
                          <CustomInput type="select" onChange={onChange} value={values.assunto} id="assunto" name="assunto">
                            <option value=""></option>
                            <option value="Dúvida">Dúvida</option>
                            <option value="Sugestão">Sugestão</option>
                            <option value="Reclamação">Reclamação</option>
                            <option value="Comercial">Comercial</option>
                            <option value="Assistência">Assistência Técnica</option>
                            <option value="Outros">Outros</option>
                          </CustomInput>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label> Telefone </Label>
                          <InputTelefone onChange={onChange} value={values.telefone} id="telefone" name="telefone" />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Mensagem * </Label>
                          <Input type="textarea" rows="4" onChange={onChange} value={values.mensagem} name="mensagem" id="mensagem" />
                        </FormGroup>
                      </Col>

                      <Col md={12} className="btn_itens text-right" style={{ margin: 'auto' }}>
                        <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                          Voltar
                        </Button>

                        <Button type="button" onClick={onSubmit} className="btn-wide mr-2 btn-lg" color="success">
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md={4}>
                <Card className="main-card mb-2">
                  <CardBody>
                    <SubTitle>Se preferir, entre em contato através dos telefones</SubTitle>
                    <ItemContact>
                      <BsFillTelephoneFill color="primary" size={16} />
                      <span>
                        Informações Técnicas e Científicas: <LinkContact href="tel:08008923079">0800 892 30 79</LinkContact>
                      </span>
                    </ItemContact>
                    <ItemContact>
                      <BsFillTelephoneFill color="primary" size={16} />
                      <span>
                        Atendimento Comercial: <LinkContact href="tel:01131454326">(11) 3145-4326</LinkContact>
                      </span>
                    </ItemContact>
                    <ItemContact>
                      <BsFillTelephoneFill color="primary" size={16} />
                      <span>
                        Atendimento ao Cliente: <LinkContact href="tel:04131800821">(41) 3180–0821</LinkContact>
                      </span>
                    </ItemContact>

                    <TimeContact>
                      <BsFillClockFill color="primary" size={16} />
                      <span>Horário de atendimento: Segunda a Sexta-feira das 9h às 18h</span>
                    </TimeContact>

                    <AddressContact>
                      Rua Joaquim Nabuco, 615 - Bairro Cidade Jardim <br />
                      São José dos Pinhais / PR - Brasil - CEP: 83040-210
                    </AddressContact>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
