import React, { Fragment } from 'react'
import { FiFolder } from 'react-icons/fi'
import { Col } from 'reactstrap'
import { Container, CardName } from './styles'
function Component({ folder, onChange, cardSize = 3, ...inputProps }) {
  return (
    <Fragment>
      <Col md={cardSize}>
        <Container
          onClick={() => {
            onChange(folder.sysArquivoId)
          }}
        >
          <FiFolder size={32} />
          <CardName>{folder.nome}</CardName>
        </Container>
      </Col>
    </Fragment>
  )
}

export default Component
