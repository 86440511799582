import React, { useEffect, useState } from 'react'

import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button, CardTitle, Table } from 'reactstrap'
import { AsyncSelect, InputReal, InputNumber, i18n, toast, Bounce } from '../../Components'

import { BsFillTrashFill, BsPencil } from 'react-icons/bs'
import { MateriaisGet, LoteGet, MateriaisGetRecentes } from '../../Service/ApiService'
import ModalLot from './ModalLot'
import { MdList } from 'react-icons/md'
import { MaskReal } from '../../helpers/functions'

const initValues = { ordItemId: 0, materialId: '', materialIdExt: '', nmMaterial: '', loteId: '', nrLote: '', dtPrevEntrega: '', preco: null, qtde: '', material: '', index: '' }

export default function Element({ onChange, tpOrdemId, itens = [], obrigaLote }) {
  const [values, setValues] = useState(initValues)
  const [materiais, setMateriais] = useState()
  const [lotes, setLotes] = useState()
  const [confModal, setConfModal] = useState({ showModal: false, materialId: '', index: '' })
  const [total, setTotal] = useState(0)

  function onChangeI(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  async function onChangeMaterial(ev) {
    if (ev) {
      setValues({
        ...initValues,
        material: ev,
        materialId: ev.value,
        materialIdExt: ev.materialIdExt,
        nmMaterial: ev.label,
        uniMedida: ev.uniMedida,
        preco: null,
      })

      loadLotes(ev.value)
    } else {
      setValues({
        ...initValues,
        material: '',
        materialId: '',
        materialIdExt: '',
        nmMaterial: '',
        uniMedida: '',
        preco: null,
      })
    }
  }
  async function loadLotes(materialId, index = '') {
    var response = await LoteGet({ materialId, disponivel: true })
    setLotes(response)

    if (response.length > 0) {
      setConfModal({ ...confModal, showModal: true, lotes: response, index })
    }
  }

  useEffect(() => {
    if (tpOrdemId) {
      //loadMateriais()
      setValues({ ...initValues })
      onLoadMatRecentes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tpOrdemId])

  useEffect(() => {
    let _total = 0
    itens.forEach((item) => {
      if (item.preco !== null) _total += item.qtde * item.preco
    })
    setTotal(_total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens])

  async function onSalvar() {
    if (!tpOrdemId) {
      toast(i18n.t('order.itens.MSG_ValidateTpOrder'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (!values.materialId || !values.qtde) {
      toast(i18n.t('order.itens.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (obrigaLote > 0 && !values.loteId) {
      toast(i18n.t('order.itens.MSG_ErrorValidateLot'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      setConfModal({ ...confModal, showModal: true, lotes })
      return
    }

    let _itens = []
    itens.forEach((item, ind) => {
      if (values.index !== ind) _itens.push({ ...item })
    })
    _itens.push(values)
    onChange(_itens)

    setValues({ ...initValues })

    //setConfModal({ ...confModal, showModal: true })}
  }

  function onModal(ev) {
    setConfModal({ ...confModal, showModal: !confModal.showModal, index: '' })
    if (ev?.index !== '' && ev?.index !== undefined) {
      let _itens = []
      itens.forEach((item, index) => {
        if (index === ev.index) {
          _itens.push({ ...item, ...ev })
        } else _itens.push({ ...item })
        onChange(_itens)
      })
    } else setValues({ ...values, ...ev })
  }

  async function onLoadMateriais(ev) {
    let items = []
    if (ev.trim().length > 3) {
      var result = await MateriaisGet({ ativo: true, tpOrdemId, textPesquisa: ev.trim(), obrigaLote })

      if (result)
        result.forEach((item) => {
          if (item.nome.length > 0) items.push({ value: item.materialId, label: `${item.materialIdExt} - ${item.nome}`, ...item })
        })
    }
    setMateriais(items)
    return items
  }

  async function onLoadMatRecentes() {
    let items = []
    var result = await MateriaisGetRecentes({ obrigaLote, tpOrdemId })
    if (result)
      result.forEach((item) => {
        if (item.nome.length > 0) items.push({ value: item.materialId, label: `${item.materialIdExt} - ${item.nome}`, ...item })
      })
    setMateriais(items)
  }

  async function onSelectLot(index) {
    loadLotes(itens[index].materialId, index)
  }

  async function onRemove(index) {
    let _itens = []
    itens.forEach((item, ind) => {
      if (index !== ind) _itens.push(item)
    })
    onChange(_itens)
  }
  async function onEdit(index) {
    setValues({ ...itens[index], index })
  }

  return (
    <>
      <ModalLot onChange={onModal} showModal={confModal.showModal} lotes={confModal.lotes} index={confModal.index} />

      <Card className="main-card mb-2">
        <CardBody>
          <CardTitle style={{ display: 'flex' }}>
            <span>Itens</span>
          </CardTitle>

          {itens.length > 0 && (
            <Row>
              <Col className="tb-report position-relative table-responsive">
                <Table striped className="mb-4">
                  <thead>
                    <tr>
                      <th>{i18n.t('order.itens.grid.materialId')} </th>
                      <th>{i18n.t('order.itens.grid.materialName')}</th>
                      <th>{i18n.t('order.itens.grid.unit')}</th>
                      <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.qtde')}</th>
                      <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.unitValue')}</th>
                      <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.amount')}</th>
                      {obrigaLote === true && <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.lotNumber')}</th>}
                      <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.lotAvailability')}</th>
                      <th style={{ textAlign: 'center' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {itens.map((d, index) => (
                      <tr key={index}>
                        <td>{d.materialIdExt}</td>
                        <td>{d.nmMaterial}</td>
                        <td>{d.uniMedida}</td>
                        <td style={{ textAlign: 'center' }}>{d.qtde}</td>
                        <td style={{ textAlign: 'center' }}>{MaskReal(d.preco)}</td>
                        <td style={{ textAlign: 'right' }}>{d.preco != null ? MaskReal(d.qtde * d.preco) : ''}</td>
                        {obrigaLote === true ? (
                          <>
                            {d.nrLote ? (
                              <td style={{ textAlign: 'center' }}>{d.nrLote}</td>
                            ) : (
                              <td style={{ textAlign: 'center' }}>
                                <Button
                                  className="btn btn-hover-shine btn btn-light"
                                  onClick={() => {
                                    onSelectLot(index)
                                  }}
                                >
                                  <MdList size={14} />
                                </Button>
                              </td>
                            )}
                            <td style={{ textAlign: 'center' }}>{d.dtPrevEntrega}</td>
                          </>
                        ) : (
                          <td style={{ textAlign: 'center' }}>{d.dtPrevEntrega}</td>
                        )}
                        <td style={{ textAlign: 'right' }}>
                          <Button
                            className="btn btn-danger btn-sm"
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                              onRemove(index)
                            }}
                          >
                            <BsFillTrashFill size={14} />
                          </Button>
                          <Button
                            className="btn btn-primary btn-sm"
                            color="primary"
                            onClick={() => {
                              onEdit(index)
                            }}
                          >
                            <BsPencil size={14} />
                          </Button>
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan="6" style={{ marginTop: '10px', textAlign: 'right' }}>
                        <strong> {MaskReal(total)}</strong>
                      </td>
                      {obrigaLote === true && <td></td>}
                      <td colSpan="2"></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.material')} *</Label>

                <AsyncSelect
                  placeholder="Digite para pesquisar"
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                  name="material"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  loadOptions={onLoadMateriais}
                  onChange={(ev) => {
                    onChangeMaterial(ev)
                  }}
                  value={values.material}
                  defaultOptions={materiais}
                  isClearable
                  isDisabled={!tpOrdemId}
                />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.qtde')} *</Label>
                <InputNumber onChange={onChangeI} value={values.qtde} name="qtde" id="qtde" disabled={!tpOrdemId} />
              </FormGroup>
            </Col>
            <Col md={obrigaLote === true ? 1 : 2}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.unitValue')}</Label>
                <InputReal value={values.preco ? values.preco : ''} name="preco" id="preco" disabled />
              </FormGroup>
            </Col>
            <Col md={obrigaLote === true ? 1 : 2}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.amount')}</Label>
                <InputReal value={values.preco ? values.preco * values.qtde : ''} name="valorTot" id="valorTot" disabled />
              </FormGroup>
            </Col>
            {obrigaLote === true && (
              <Col md={2}>
                <FormGroup>
                  <Label>{i18n.t('order.itens.form.lotNumber')} </Label>
                  <div style={{ display: 'flex' }}>
                    <Input style={{ marginRight: '2px' }} type="text" value={values.nrLote} name="nrLote" id="nrLote" disabled />
                    <Button
                      disabled={lotes?.length === 0 || lotes === undefined ? true : false}
                      className="btn btn-hover-shine btn btn-light"
                      onClick={() => {
                        setConfModal({ ...confModal, showModal: true, materialId: values.materialId })
                      }}
                    >
                      <MdList size={14} />
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            )}

            <Col md={1}>
              <FormGroup>
                <div style={{ marginTop: '25px' }}>
                  <Button type="button" className="btn-block btn-lg" onClick={onSalvar} color="success" style={{ marginRight: '5px' }}>
                    {i18n.t('order.itens.form.buttonSave')}
                  </Button>
                </div>
              </FormGroup>
            </Col>

            {/* <Button type="button" onClick={onAdicionar} color="success" style={{ margin: '0 0 0 auto' }}>
                 <BsPlus size={18} /> {i18n.t('order.itens.form.buttonAdd')}
               </Button> */}
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
