import { Fragment, React } from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import AppLogo from '../AppLogo'
import UserBox from './UserBox'

const Header = () => {
  const enableMobileMenuSmall = useSelector((state) => state.enableMobileMenuSmall)
  let { headerBackgroundColor = 'bg-strong-bliss header-text-light', enableHeaderShadow = true } = {}

  const enableClosedSidebar = useSelector((state) => state.enableClosedSidebar)

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" className={cx('app-header', headerBackgroundColor, { 'header-shadow': enableHeaderShadow })} transitionName="HeaderAnimation" transitionAppear={true} transitionAppearTimeout={1500} transitionEnter={false} transitionLeave={false}>
        <AppLogo />

        <div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
          <div className="app-header-left">
            <h2 className="app-header-title"> </h2>
          </div>
          {enableClosedSidebar && (
            <div className="app-header-left">
              <div className="header-logo-src logo-src"></div>
            </div>
          )}
          <div className="app-header-right">
            <UserBox />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default Header
