import React, { Fragment, useEffect, useState } from 'react'

import { Card, CardBody, Row, Col, FormGroup, Label, Button, CardTitle, Table } from 'reactstrap'
import { InputReal, InputNumber, i18n, toast, Bounce, Select } from '../../Components'

import { BsFillTrashFill, BsPencil } from 'react-icons/bs'
import { MaskReal } from '../../helpers/functions'

const initValues = { ordItemId: 0, materialId: '', materialIdExt: '', nmMaterial: '', preco: null, qtde: '', material: '', index: '' }

export default function Element({ onChange, materiais, itens = [] }) {
  const [values, setValues] = useState(initValues)
  const [total, setTotal] = useState(0)

  function onChangeI(ev) {
    const { name, value } = ev.target
    if (name === 'qtde') {
      if (value > values.saldo) {
        toast('A quantidade não pode ser maior que ' + values.saldo, { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    }
    setValues({ ...values, [name]: value })
  }
  async function onChangeMaterial(ev) {
    if (ev) {
      setValues({ ...initValues, material: ev, materialId: ev.value, materialIdExt: ev.materialIdExt, nmMaterial: ev.label, uniMedida: ev.uniMedida, preco: ev.preco, qtde: '', saldo: ev.saldo, conMaterialId: ev?.conMaterialId, tipo: ev.tipo })
    } else setValues({ ...initValues, material: '', materialId: '', materialIdExt: '', nmMaterial: '', uniMedida: '', preco: null })
  }

  useEffect(() => {
    let _total = 0
    itens.forEach((item) => {
      if (item.preco !== null && item.tipo == 'ZREA') {
        _total += item.qtde * item.preco
      }
    })
    setTotal(_total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens])

  async function onSalvar() {
    // if (!tpOrdemId) {
    //   toast(i18n.t('order.itens.MSG_ValidateTpOrder'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    //   return
    // }
    if (!values.materialId || !values.qtde) {
      toast(i18n.t('order.itens.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    let _itens = []
    itens.forEach((item, ind) => {
      if (values.index !== ind) _itens.push({ ...item })
    })
    _itens.push(values)
    onChange(_itens)

    setValues({ ...initValues })
  }

  async function onRemove(index) {
    let _itens = []
    itens.forEach((item, ind) => {
      if (index !== ind) _itens.push(item)
    })
    onChange(_itens)
  }
  async function onEdit(index) {
    setValues({ ...itens[index], index })
  }
  function getMateriaisDisp() {
    let result = []
    if (materiais) {
      materiais
        .filter((l) => l.saldo > 0)
        .forEach((d) => {
          if (itens.filter((f) => f.materialId === d.materialId).length === 0) result.push({ value: d.materialId, label: `${d.materialIdExt} - ${d.materialNm}`, ...d })
        })
    }
    return result
  }

  return (
    <Fragment>
      <Card className="main-card mb-2">
        <CardBody>
          <CardTitle style={{ display: 'flex' }}>
            <span>Itens</span>
          </CardTitle>

          {itens.length > 0 && (
            <Row>
              <Col className="tb-report position-relative table-responsive">
                <Table striped className="mb-4">
                  <thead>
                    <tr>
                      <th>{i18n.t('order.itens.grid.materialId')} </th>
                      <th>{i18n.t('order.itens.grid.materialName')}</th>
                      <th>{i18n.t('order.itens.grid.type')}</th>
                      <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.qtde')}</th>
                      <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.unitValue')}</th>
                      <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.amount')}</th>

                      <th style={{ textAlign: 'center' }}>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itens.map((d, index) => (
                      <tr key={index}>
                        <td>{d.materialIdExt}</td>
                        <td>{d.nmMaterial}</td>
                        <td>{d.tipo}</td>
                        <td style={{ textAlign: 'center' }}>{d.qtde}</td>
                        <td style={{ textAlign: 'center', opacity: d.tipo != 'ZREA' ? '0.6' : '1' }}>{MaskReal(d.preco)}</td>
                        <td style={{ textAlign: 'right', opacity: d.tipo != 'ZREA' ? '0.6' : '1' }}>{d.preco != null ? MaskReal(d.qtde * d.preco) : ''}</td>

                        <td style={{ textAlign: 'center' }}>
                          <Button
                            className="btn btn-danger btn-sm"
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                              onRemove(index)
                            }}
                          >
                            <BsFillTrashFill size={14} />
                          </Button>
                          <Button
                            className="btn btn-primary btn-sm"
                            color="primary"
                            onClick={() => {
                              onEdit(index)
                            }}
                          >
                            <BsPencil size={14} />
                          </Button>
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan="6" style={{ marginTop: '10px', textAlign: 'right' }}>
                        <strong> {MaskReal(total)}</strong>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={5}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.material')} *</Label>

                <Select
                  placeholder="Selecione"
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                  onChange={(ev) => {
                    onChangeMaterial(ev)
                  }}
                  isClearable
                  value={values.material}
                  name="material"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={getMateriaisDisp()}
                />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.qtde')} *</Label>
                <InputNumber onChange={onChangeI} value={values.qtde} name="qtde" id="qtde" />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label>Saldo</Label>
                <InputNumber value={values.saldo} name="saldo" id="saldo" disabled />
              </FormGroup>
            </Col>

            <Col md={2}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.unitValue')}</Label>
                <InputReal value={values.preco ? values.preco : ''} name="preco" id="preco" disabled />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.amount')}</Label>
                <InputReal value={values.preco ? values.preco * values.qtde : ''} name="valorTot" id="valorTot" disabled />
              </FormGroup>
            </Col>

            <Col md={1}>
              <FormGroup>
                <div style={{ marginTop: '25px' }}>
                  <Button type="button" className="btn-block btn-lg" onClick={onSalvar} color="success" style={{ marginRight: '5px' }} disabled={values.qtde > values.saldo}>
                    {i18n.t('order.itens.form.buttonSave')}
                  </Button>
                </div>
              </FormGroup>
            </Col>

            {/* <Button type="button" onClick={onAdicionar} color="success" style={{ margin: '0 0 0 auto' }}>
                 <BsPlus size={18} /> {i18n.t('order.itens.form.buttonAdd')}
               </Button> */}
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}
