import React, { Fragment, useState } from 'react'
import { Container, CardIcon, CardName, Wrapper } from './styles'
import { SysArquivoDelete } from '../../../../Service/ApiService'
import { toast, Bounce } from '../../../../Components'
import { BsTrashFill } from 'react-icons/bs'
import { FiFolder } from 'react-icons/fi'

function Component({ values, editAccess, onChange }) {
  const [isDeleted, setIsDeleted] = useState(false)

  async function onOpen() {
    onChange({ sysArquivoId: values.sysArquivoId, nome: values.nome })
  }

  async function onDeleteFile() {
    const response = await SysArquivoDelete(values.sysArquivoId)
    if (response.result) {
      setIsDeleted(true)
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <Fragment>
      {!isDeleted && (
        <Wrapper>
          <td>
            <Container onClick={onOpen}>
              <CardIcon>
                <FiFolder size={32} />
              </CardIcon>
              <CardName>{values.nome}</CardName>
            </Container>
          </td>
          <td style={{ width: '150px', textAlign: 'right' }}>
            {values.tamanho} {values.tamanho > 1 ? 'arquivos' : 'arquivo'}
          </td>
          <td style={{ width: '100px', textAlign: 'center' }}>{values.dhUpload}</td>
          <td style={{ width: '50px' }}>
            {(values.tamanho === 0) & editAccess ? (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <button className="btn btn-info btn-sm btn-danger" onClick={onDeleteFile}>
                  <BsTrashFill size={14} />
                </button>
              </div>
            ) : (
              <Fragment />
            )}
          </td>
        </Wrapper>
      )}
    </Fragment>
  )
}

export default Component
