import React, { Fragment, useState } from 'react'
import { DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, UncontrolledButtonDropdown } from 'reactstrap'
import { AiOutlineDown } from 'react-icons/ai'
import avatar1 from '../../Assets/Images/user.png'

import flagUsa from '../../Assets/Images/flag-usa.png'
import flagPtBR from '../../Assets/Images/flag-pt-BR.png'
import flagEsp from '../../Assets/Images/flag-esp.png'
import { i18n } from '../../translate/i18n'
import { UsuarioAlterIdioma } from '../../Service/ApiService'
import LoadAcessos from '../../Components/LoadAcessos'
import { getUser } from '../../Reducers/storageApp'
import Notification from './Notification'
import Accounts from './Accounts'

function UserBox() {
  const user = getUser()
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'))
  const [isLoadAcessos, setIsLoadAcessos] = useState(false)

  function onSair() {
    localStorage.clear()
    window.location.href = '/#/login'
  }

  async function onSelectLag(lag) {
    setLanguage(lag)

    i18n.changeLanguage(lag)
    await UsuarioAlterIdioma(lag)

    setIsLoadAcessos(true)
  }

  async function onLoadAcessos() {
    window.location.reload()
  }
  const renderLan = () => {
    switch (language) {
      case 'pt-BR':
        return <img height={38} className="rounded-circle-flag" src={flagPtBR} alt="" />
      case 'en':
        return <img height={38} className="rounded-circle-flag" src={flagUsa} alt="" />
      case 'es':
        return <img height={38} className="rounded-circle-flag" src={flagEsp} alt="" />
      default:
        return <img height={38} className="rounded-circle-flag" src={flagPtBR} alt="" />
    }
  }

  return (
    <>
      {isLoadAcessos && <LoadAcessos onChange={onLoadAcessos} />}
      {user && (
        <>
          {user.isExecutivo && <Accounts />}

          <div className="header-dots pr-0">
            <div className="widget-content p-0">
              <div className="widget-content-wrapper">
                <div className="widget-content-left ml-3 header-user-info">
                  <div className="widget-heading text-center"></div>
                </div>

                <div className="widget-content-left">
                  <Notification />
                  <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className="rounded-circle-Dropdown">
                      {renderLan()}
                    </DropdownToggle>
                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                      <Nav vertical>
                        <NavItem className="nav-item-header">{i18n.t('layout.header.Language.title')}</NavItem>
                        <NavItem>
                          <NavLink className="nav-link-lag" onClick={() => onSelectLag('en')}>
                            <img style={{ opacity: language === 'en' ? '1' : '0.5' }} height={32} className="nav-link-icon rounded-circle" src={flagUsa} alt="" />
                            <span>{i18n.t('layout.header.Language.english')}</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className="nav-link-lag" onClick={() => onSelectLag('pt-BR')}>
                            <img style={{ opacity: language === 'pt-BR' ? '1' : '0.5' }} height={32} className="nav-link-icon rounded-circle" src={flagPtBR} alt="" />
                            <span>{i18n.t('layout.header.Language.portuguese')}</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className="nav-link-lag" onClick={() => onSelectLag('es')}>
                            <img style={{ opacity: language === 'es' ? '1' : '0.5' }} height={32} className="nav-link-icon rounded-circle" src={flagEsp} alt="" />
                            <span>{i18n.t('layout.header.Language.Spanish')}</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="header-btn-lg pr-0">
            <div className="widget-content p-0">
              <div className="widget-content-wrapper">
                <div className="widget-content-left ml-3 header-user-info"></div>

                <div className="widget-content-left">
                  <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className="p-0">
                      <img width={38} className="rounded-circle" src={avatar1} alt="" />
                      <AiOutlineDown className="ml-2 opacity-8" color="var(--white)" size={14} />
                    </DropdownToggle>

                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                      <Nav vertical>
                        <NavItem>
                          <NavLink href="/#/Admin/User/Profile">{i18n.t('layout.header.profile')}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink onClick={onSair}>{i18n.t('layout.header.logOut')}</NavLink>
                        </NavItem>
                      </Nav>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>

                <div className="widget-content-left  ml-3 header-user-info">
                  <div className="widget-heading">{user?.name}</div>
                  <div className="widget-subheading">{user?.contaNm}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default UserBox
