import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Alert } from 'reactstrap'

import { ConTpOrdemGetQtdeOrdemCIF, OrdemAddOrUpdate, OrdemGetById, OrdemGetPreco, ParticipanteGetById, ParticipantesGet, TpOrdemGet, TpOrdemGetQtdeOrdensMes } from '../../../Service/ApiService'
import { useDispatch, PageTitle, ReactCSSTransitionGroup, useNavigate, toast, Bounce, useSelector, i18n, Select, Loading } from '../../../Components'

import { BiErrorAlt } from 'react-icons/bi'
import Itens from '../Item'
import ItensBom from '../ItemBom'

import ModalPreco from '../ModalPreco'
import Import from './Import'
import ModalImportLog from './ModalImportLog'
import ModalMessageCIF from '../ModalMessageCIF'
import { getUser } from '../../../Reducers/storageApp'

const initValues = { ordemId: 0, ContaId: '', tpOrdem: '', participante: '', parEndereco: '', itens: [], observacao: '', pedCompra: '', log: [] }

export default function FormCadastro() {
  const user = getUser()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(initValues)
  const [tpOrdens, setTpOrdens] = useState()
  const [participantes, setParticipantes] = useState()
  const OrdemId = useSelector((state) => state.OrdemId)
  const [impDados, setImpDados] = useState(false)

  const pageEndRef = React.createRef()
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
  const [confModal, setConfModal] = useState({ showModal: false })
  const [confCIF, setConfCIF] = useState({ showModal: false })
  const [modalPreco, setModalPreco] = useState({ show: false })

  async function loadTpOrdens(id) {
    var response = await TpOrdemGet({ ativo: true, contaId: user.contaId })
    let items = []
    let result = {}
    if (response)
      response.forEach((item) => {
        if (id === item.tpOrdemId)
          result = {
            value: item.tpOrdemId,
            label: item.nome,
            obrigaLote: item.obrigaLote,
            nrDiasPrevEntrega: item.nrDiasPrevEntrega,
            diaSemana: item.diaSemana,
            diasAntecedencia: item.diasAntecedencia,
            isBillOfMaterial: item.isBillOfMaterial,
            tpCalcEntrega: item.tpCalcEntrega,
          }

        items.push({
          value: item.tpOrdemId,
          label: item.nome,
          obrigaLote: item.obrigaLote,
          nrDiasPrevEntrega: item.nrDiasPrevEntrega,
          diaSemana: item.diaSemana,
          diasAntecedencia: item.diasAntecedencia,
          qtdeOrdemCIF: item.qtdeOrdemCIF,
          isBillOfMaterial: item.isBillOfMaterial,
          tpCalcEntrega: item.tpCalcEntrega,
        })
      })
    setTpOrdens(items)
    return result
  }
  async function loadParticipantes(id) {
    var response = await ParticipantesGet({ ativo: true, contaId: user.contaId })
    let items = []
    let result = {}
    if (response) {
      response.forEach((item) => {
        if (id === item.participanteId) result = { value: item.participanteId, label: item.nomeFantasia, bloqCredito: item.bloqCredito }
        items.push({ value: item.participanteId, label: item.nomeFantasia, bloqCredito: item.bloqCredito })
      })
      if (response.length === 1) {
        const part = await loadParEndereco({ value: response[0].participanteId, label: response[0].nomeFantasia })
        setValues({ ...values, ...part })
      }
    }
    setParticipantes(items)
    return result
  }
  async function loadParEndereco(ev) {
    var result = await ParticipanteGetById(ev.value)
    return { participante: ev, parEndereco: `${result.endLogradouro} ${result.endNumero} - ${result.endBairro} - ${result.endCidade} - ${result.endEstado} - ${result.endPais}` }
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  async function onChangeCat(name, ev) {
    if (!ev) {
      setValues({ ...values, [name]: ev })
      return
    }
    if (name === 'participante') {
      const part = await loadParEndereco(ev)
      setValues({ ...values, ...part })
    } else setValues({ ...values, [name]: ev })
  }

  async function onSubmit(situacao) {
    if (!values.participante || !values.tpOrdem || !values.pedCompra) {
      toast(i18n.t('order.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (values.itens.length === 0) {
      toast(i18n.t('order.add.MSG_ErrorValidateItens'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (situacao === '20') {
      var contaQtdeOrdemCIF = await ConTpOrdemGetQtdeOrdemCIF(values.tpOrdem.value)
      if ((contaQtdeOrdemCIF === undefined ? values.tpOrdem?.qtdeOrdemCIF : contaQtdeOrdemCIF) > 0) {
        var qtdeOrdensMes = await TpOrdemGetQtdeOrdensMes({ contaId: user.contaId, tpOrdemId: values.tpOrdem.value })

        if (qtdeOrdensMes >= (contaQtdeOrdemCIF === undefined ? values.tpOrdem?.qtdeOrdemCIF : contaQtdeOrdemCIF)) {
          setConfCIF({ ...confCIF, showModal: true })
          return
        }
      }
    }

    if (values.tpOrdem?.obrigaLote === true) {
      let isErrorItensLot = false
      values.itens.forEach((item) => {
        if (!item.loteId) isErrorItensLot = true
      })
      if (isErrorItensLot === true) {
        toast(i18n.t('order.add.MSG_ErrorItensLot'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return
      }
    }

    onSalvar(situacao)
  }

  function MontaPedido(situacao) {
    var _values = {}
    var _itens = []
    _values.participanteId = values.participante.value
    _values.tpOrdemId = values.tpOrdem.value
    _values.contaId = user.contaId
    _values.observacao = values.observacao
    _values.situacao = situacao
    _values.pedCompra = values.pedCompra

    values.itens.forEach((item) => {
      _itens.push({ materialId: item.materialId, preco: item.preco, qtde: item.qtde, loteId: item.loteId })
    })
    _values.itens = _itens

    return _values
  }

  async function onSalvar(situacao) {
    setIsLoading(true)

    try {
      const response = await OrdemAddOrUpdate(MontaPedido(situacao))
      if (response)
        if (response.result) window.location.href = '/#/admin/Order/Query'
        else toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } catch (error) {
      toast('Ops, ocorreu um erro ao processar o pedido, informe ao suporte técnico', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    async function load() {
      await loadTpOrdens()
      await loadParticipantes()

      if (OrdemId) {
        onCopyOrder(OrdemId)
        dispatch({ type: 'OrdemId', value: undefined })
      }
    }
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onCopyOrder(ordemId) {
    const response = await OrdemGetById(ordemId)
    if (response) {
      let _values = {}

      _values.ordemId = 0
      _values.pedCompra = response.ordem.pedCompra
      _values.observacao = response.ordem.observacao

      var _participante = { value: response.ordem.participanteId, label: response.ordem.participanteNm }
      const _parEndereco = await loadParEndereco(_participante)
      _values.participante = _participante
      _values = { ..._values, ..._parEndereco }

      _values.tpOrdem = await loadTpOrdens(response.ordem.tpOrdemId)

      let itens = []
      response.itens.forEach((item, ind) => {
        itens.push({
          ordItemId: item.ordItemId,
          materialId: item.materialId,
          materialIdExt: item.materialIdExt,
          nmMaterial: item.nmMaterial,
          preco: '',
          qtde: item.qtde,
          uniMedida: item.material.uniMedida,
          material: { value: item.materialId, label: item.nmMaterial },
          index: ind,
        })
      })
      _values.itens = itens

      setValues({ ...values, ..._values })
    }
  }

  async function onImpDados(ev) {
    setValues({ ...values, ...ev })
    setImpDados(false)
    if (ev.log?.length > 0) onModal()
  }

  async function onItem(ev) {
    setValues({ ...values, itens: ev })
    if (values.tpOrdem?.isBillOfMaterial !== 'Sim') scrollToRef(pageEndRef)
  }

  function onModal() {
    setConfModal({ ...confModal, showModal: !confModal.showModal })
  }
  function onModalCIF(ev) {
    setConfCIF({ ...confCIF, showModal: !confCIF.showModal })
    if (ev === true) onSalvar('20')
  }

  async function onGetPreco() {
    setIsLoading(true)
    try {
      var _values = MontaPedido('05')
      const response = await OrdemGetPreco(_values)

      if (response?.result) {
        let _itens = []
        for (let index = 0; index < values.itens.length; index++) {
          _itens.push({ ...values.itens[index], preco: response?.itens[index].preco })
        }

        setValues({ ...values, itens: _itens })
      } else toast('Ops, ocorreu um problema ao realizar a cotação', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } catch {}
    setIsLoading(false)
  }

  function onSendOrder() {
    let isHasPreco = true
    values.itens.forEach((item) => {
      if (!item.preco > 0) {
        isHasPreco = false
      }
    })

    if (isHasPreco === false) {
      setModalPreco({ show: true })
    } else onSubmit('20')
  }

  async function onModalPreco(ev) {
    setModalPreco({ show: false })
    if (ev === true) {
      await onGetPreco()
    } else if (ev === false) onSubmit('20')
  }

  return (
    <>
      <Loading isVisible={isLoading} />
      <ModalImportLog onChange={onModal} showModal={confModal.showModal} dataGrid={values.log} />
      <ModalMessageCIF onChange={onModalCIF} showModal={confCIF.showModal} />
      {modalPreco.show && <ModalPreco onChange={onModalPreco} />}

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('order.add.heading')} subheading={i18n.t('order.add.subheading')} icon="BsFillCartFill">
            {!impDados && (
              <Fragment>
                {values.log?.length > 0 && (
                  <Button type="button" onClick={() => onModal()} className="btn-pill" color="danger" title="Erros" style={{ marginRight: '5px' }}>
                    <BiErrorAlt size={16} />
                  </Button>
                )}

                <Button type="button" onClick={() => setImpDados(true)} className="btn-pill" color="info" style={{ marginRight: '5px' }}>
                  Importar pedido
                </Button>
              </Fragment>
            )}
          </PageTitle>
          <div className="page-content">
            {impDados ? (
              <Import onChange={onImpDados} />
            ) : (
              <>
                {values.participante?.bloqCredito === 'Sim' && (
                  <Alert color="danger">
                    Seu cadastro possuí pendências ou inconsistências. Por gentileza entre em{' '}
                    <a href="/Admin/Contact" className="alert-link">
                      contato com a Sysmex
                    </a>{' '}
                    para regularização. Não será possível o envio de novas ordens.
                  </Alert>
                )}

                <Card className="main-card mb-2">
                  <CardBody>
                    <Row>
                      <Col md={2}>
                        <FormGroup>
                          <Label>{i18n.t('order.add.form.nrOv')} </Label>
                          <Input type="text" name="ordemIdExt" id="ordemIdExt" disabled />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label>{i18n.t('order.edit.form.myOrder')} *</Label>
                          <Input type="text" name="pedCompra" id="pedCompra" value={values.pedCompra} onChange={onChange} maxLength={16} />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <Label>{i18n.t('order.add.form.tpOrder')} *</Label>
                        <Select
                          placeholder="Selecione"
                          noOptionsMessage={() => 'Nenhuma opção disponível'}
                          onChange={(ev) => {
                            onChangeCat('tpOrdem', ev)
                          }}
                          isClearable
                          value={values.tpOrdem}
                          name="tpOrdem"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={tpOrdens}
                          isDisabled={values.itens.length > 0 ? true : false}
                        />
                      </Col>
                      {/* <Col md={2} style={{ display: values.tpOrdem?.obrigaLote === true ? 'none' : 'unset' }}>
                        <FormGroup>
                          <Label>{i18n.t('order.add.form.deliveryDate')} </Label>
                          <Input type="text" value={i18n.t('order.add.form.dtPrevAguardando')} disabled />
                        </FormGroup>
                      </Col> */}
                      <Col md={4}>
                        <FormGroup>
                          <Label>{i18n.t('order.add.form.buyer')} </Label>
                          <Input type="text" value={user?.name} name="nmUsuario" id="nmUsuario" disabled />
                        </FormGroup>
                      </Col>

                      <Col md={5}>
                        <Label>{i18n.t('order.add.form.customer')} * </Label>
                        <Select
                          placeholder="Selecione"
                          noOptionsMessage={() => 'Nenhuma opção disponível'}
                          onChange={(ev) => {
                            onChangeCat('participante', ev)
                          }}
                          isClearable
                          value={values.participante}
                          name="participante"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={participantes}
                        />
                      </Col>

                      <Col md={7}>
                        <Label> {i18n.t('order.add.form.customerEnd')} </Label>
                        <Input type="text" value={values.parEndereco} disabled />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {values.tpOrdem?.isBillOfMaterial === 'Sim' ? <ItensBom onChange={onItem} tpOrdemId={values.tpOrdem?.value} itens={values.itens} /> : <Itens onChange={onItem} tpOrdemId={values.tpOrdem?.value} itens={values.itens} obrigaLote={values.tpOrdem?.obrigaLote} />}

                <Card className="main-card mb-2">
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label>{i18n.t('order.add.form.observation')} </Label>
                          <Input type="textarea" rows="2" onChange={onChange} value={values.observacao} name="observacao" id="observacao" />
                        </FormGroup>
                      </Col>

                      <Col md={6} className="btn_itens text-right" style={{ margin: 'auto' }}>
                        <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                          {i18n.t('layout.btnGoBack')}
                        </Button>

                        <Button type="button" className="btn-wide mr-2 btn-lg" color="focus" onClick={onGetPreco} disabled={values.itens?.length > 0 ? false : true}>
                          Calcular preço
                        </Button>

                        {values.participante?.bloqCredito !== 'Sim' && (
                          <Button type="button" onClick={onSendOrder} className="btn-wide mr-2 btn-lg" color="success">
                            {i18n.t('order.add.form.buttonSend')}
                          </Button>
                        )}

                        {values.tpOrdem?.isBillOfMaterial === 'Não' && (
                          <Button
                            type="button"
                            onClick={() => {
                              onSubmit('10')
                            }}
                            className="btn-wide mr-2 btn-outline-primary btn-square btn-lg"
                          >
                            {i18n.t('order.add.form.buttonSave')}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            )}
          </div>
        </div>
      </ReactCSSTransitionGroup>
      <div ref={pageEndRef} />
    </>
  )
}
