import styled from 'styled-components'

export const Wrapper = styled.tr`
  :hover {
    background-color: var(--secondary);

    cursor: pointer;
  }
`

export const Container = styled.div`
  //border: 2px solid var(--gray);
  //border-radius: 4px;

  //margin: 5px 0;
  padding: 6px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
`
export const CardIcon = styled.div`
  width: 30px !important;
  margin: 0 !important;
`

export const CardName = styled.div`
  padding-left: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const BoxDel = styled.div`
  text-align: right;

  margin: 0;
  padding: 0;
  z-index: 999;
  margin-top: 4px;
  margin-left: calc(100% - 35px);
  position: absolute;

  background-color: var(--red);
  border-radius: 10%;

  :hover {
    margin-top: 4px;
    margin-left: calc(100% - 39px);
  }

  i:nth-child(1) {
    opacity: 0.8;
    font-size: 16px;
    padding: 5px 4px;
    font-weight: bold;
    color: white;
  }
  i:nth-child(1):hover {
    opacity: 1;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;

    > svg {
      margin-top: 3px;
    }
  }
`
