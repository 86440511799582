/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Modal, ModalBody, ModalHeader, Col, ModalFooter, Button } from 'reactstrap'

import { i18n, toast, Bounce } from '../../../Components'
import { OrdemForRascunho } from '../../../Service/ApiService'

export default function Element({ onChange, showModal, ordemId }) {

  async function onSubmit() {
    var resp = await OrdemForRascunho( ordemId)
    if (resp) onChange(true)
    else toast(i18n.t('order.details.modAltStatus.MSG_Error'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }

  return (
    <Modal isOpen={showModal} size="lg" toggle={() => onChange(false)} style={{ width: '100%' }}>
      <ModalHeader toggle={() => onChange(false)}>{i18n.t('order.details.modAltStatus.title')}</ModalHeader>
      <ModalBody>
        <Col md={12}>
          <span style={{ color: 'var(--danger)', fontSize: '1.5rem' }}>{i18n.t('order.details.modAltStatus.msg')}</span>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="btn-wide mr-2 btn-lg" onClick={() => onChange(false)} color="secondary">
          {i18n.t('order.details.modAltStatus.btnCancel')}
        </Button>
        <Button type="button" className="btn-wide btn-lg" color="success" onClick={onSubmit}>
          {i18n.t('order.details.modAltStatus.btnConfirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
