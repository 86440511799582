import React, { useState, useEffect } from 'react'
import { Card, CardBody, Row, Col, CustomInput, FormGroup, Label, Input, Button, Nav, NavItem, NavLink, TabContent, TabPane, CardTitle, Table } from 'reactstrap'

import { TpOrdemGetById, MatGrupoGet, TpOrdemAddOrUpdate, SegmentoGet, MateriaisGet, TpOrdemAddCalcEnt, TpOrdemGetCalcEntregaByTpOrdem, TpOrdemRemoveCalcEnt } from '../../../Service/ApiService'
import { AsyncSelect, PageTitle, ReactCSSTransitionGroup, useNavigate, toast, Bounce, i18n, Select, InputCheck, InputNumber, Tooltip, SelectGroupLabel, useParams, InputReal } from '../../../Components'
import { BsFillTrashFill } from 'react-icons/bs'

const initValues = {
  tpOrdemId: 0,
  tpOrdemIdExt: '',
  ativo: '',
  nome: '',
  matGrupos: [],
  materiais: [],
  segmentos: [],
  obrigaLote: undefined,
  nrDiasPrevEntrega: '',
  diaSemana: '',
  diasAntecedencia: '',
  nmDiasAntecedencia: '',
  qtdeOrdemCIF: '',
  isBillOfMaterial: undefined,
  tpCalcEntrega: '0',
  Subtipo: 'OT',
  vlMinPedido: '',
  diasExcecao: '',
  matGruposExcecao: '',
}
const initValCalcEnt = { diaSemanaPed: '', horaPed: '', diaSemanaEnt: '' }

export default function FormEdit() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [values, setValues] = useState(initValues)
  const [valCalcEnt, setValCalcEnt] = useState(initValCalcEnt)
  const [matGrupos, setMatGrupos] = useState()
  const [materiais, setMateriais] = useState()
  const [segmentos, setSegmentos] = useState()
  const [listCalcEntrega, setListCalcEntrega] = useState()

  //Carrega os grupos de materiais e retorna Grupos configurados no tipo e Grupos para excessão
  async function loadMatGrupos(tpOrdensMat, matGruposExcecao) {
    const response = await MatGrupoGet()
    let items = []
    let rMatGrupos = []
    let rMatGruposExcecao = []
    let subItems = []
    var grupo = ''
    response.forEach((item) => {
      if (tpOrdensMat.includes(item.matGrupoId)) rMatGrupos.push({ value: item.matGrupoId, label: item.subGrupo })
      if (matGruposExcecao?.includes(item.matGrupoId)) rMatGruposExcecao.push({ value: item.matGrupoId, label: item.subGrupo })

      if (grupo !== '' && item.grupo !== grupo) {
        items.push({ label: grupo, options: [...subItems] })
        subItems = []
      }
      grupo = item.grupo
      subItems.push({ value: item.matGrupoId, label: item.subGrupo })
    })
    if (subItems) items.push({ label: grupo, options: [...subItems] })
    setMatGrupos(items)
    return { matGrupos: rMatGrupos, matGruposExcecao: rMatGruposExcecao }
  }

  async function onLoadMateriais(ev) {
    let items = []
    if (ev.trim().length > 3) {
      var result = await MateriaisGet({ ativo: true, textPesquisa: ev.trim() })

      if (result)
        result.forEach((item) => {
          if (item.nome.length > 0) items.push({ value: item.materialId, label: `${item.materialIdExt} - ${item.nome}`, ...item })
        })
    }
    setMateriais(items)
    return items
  }

  async function loadSegmentos(segmentos) {
    const response = await SegmentoGet({ ativo: true })
    let items = []
    let result = []
    response.forEach((item) => {
      if (segmentos.includes(item.segmentoId)) result.push({ value: item.segmentoId, label: item.nome })
      items.push({ value: item.segmentoId, label: item.nome })
    })
    setSegmentos(items)
    return result
  }

  async function loadValues() {
    const response = await TpOrdemGetById(id)
    if (response) {
      var matGrupos = await loadMatGrupos(response.tpOrdensMat, response.matGruposExcecao)

      response.segmentos = await loadSegmentos(response.segmentos)
      response.materiais = response.tpOrdensPro
      response.tpCalcEntrega = response.tpCalcEntrega.toString()

      if (response.diaSemana === null) response.diaSemana = ''
      if (response.nrDiasPrevEntrega === null) response.nrDiasPrevEntrega = ''

      loadCalcEntrega()
      setValues({ ...response, ...matGrupos })
    }
  }
  async function loadCalcEntrega() {
    const response = await TpOrdemGetCalcEntregaByTpOrdem(id)
    if (response) {
      setListCalcEntrega(response)
    }
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (values.obrigaLote === true) {
      setValues({ ...values, diaSemana: '', nrDiasPrevEntrega: '', diasAntecedencia: '', nmDiasAntecedencia: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.obrigaLote])

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCalcEnt(ev) {
    const { name, value } = ev.target
    setValCalcEnt({ ...valCalcEnt, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.nome || !values.tpOrdemIdExt || !values.ativo) {
      toast(i18n.t('tpOrder.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (values.tpCalcEntrega === '2' && listCalcEntrega?.length === 0) {
      toast('Para cálculo de entrega avançado, é obrigatório definir pelo menos uma regra!', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (values.diasExcecao > 0 && values.matGruposExcecao?.length === 0) {
      toast('Selecione os grupos de materiais com EXCEÇÕES no cálculo de entrega', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    let _values = { ...values }
    _values.tpOrdemId = parseInt(_values.tpOrdemId)
    _values.matGrupos = []
    if (_values.matGrupos) {
      values.matGrupos.forEach((element) => {
        _values.matGrupos.push(element.value)
      })
    }

    _values.segmentos = []
    if (values.segmentos) {
      values.segmentos.forEach((element) => {
        _values.segmentos.push(element.value)
      })
    }

    _values.materiais = []
    if (values.materiais) {
      values.materiais.forEach((element) => {
        _values.materiais.push(element.value)
      })
    }

    _values.matGruposExcecao = []
    if (values.matGruposExcecao) {
      values.matGruposExcecao.forEach((element) => {
        _values.matGruposExcecao.push(element.value)
      })
    }
    const response = await TpOrdemAddOrUpdate(_values)

    if (response.result === false) toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    else window.location.href = '/#/admin/TpOrder/Query'
  }

  async function onAddRegra() {
    if (!valCalcEnt.diaSemanaPed || !valCalcEnt.diaSemanaEnt) {
      toast(i18n.t('tpOrder.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    valCalcEnt.TpOrdemId = values.tpOrdemId
    const response = await TpOrdemAddCalcEnt(valCalcEnt)

    if (response.result === false) {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } else {
      setValCalcEnt(initValCalcEnt)
      loadCalcEntrega()
    }
  }
  async function onRemoveRegra(id) {
    const response = await TpOrdemRemoveCalcEnt(id)
    if (response.result === false) {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } else {
      loadCalcEntrega()
    }
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <div className="body-tabs body-tabs-layout">
        <PageTitle heading={i18n.t('tpOrder.edit.heading')} subheading={i18n.t('tpOrder.edit.subheading')}>
          <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
            {i18n.t('layout.btnGoBack')}
          </Button>
          <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
            {i18n.t('tpOrder.add.form.buttonSave')}
          </Button>
        </PageTitle>

        <div className="page-content">
          <Card className="main-card mb-2">
            <CardBody>
              <Row>
                <Col md={2}>
                  <FormGroup>
                    <Label>{i18n.t('tpOrder.edit.form.codeExt')} *</Label>
                    <Input type="text" onChange={onChange} name="tpOrdemIdExt" id="tpOrdemIdExt" value={values.tpOrdemIdExt} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Label>{i18n.t('tpOrder.edit.form.name')} *</Label>
                  <Input type="text" onChange={onChange} name="nome" id="nome" value={values.nome} />
                </Col>
                <Col md={2}>
                  <Label>Subtipo *</Label>
                  <CustomInput type="select" id="subtipo" name="subtipo" value={values.subtipo} disabled>
                    <option value=""></option>
                    <option value="OT">Outros</option>
                    <option value="CB">Contrato Bonificação</option>
                    <option value="CN">Contrato Normal</option>
                  </CustomInput>
                </Col>
                <Col md={2}>
                  <Label>{i18n.t('tpOrder.edit.form.ative.title')}</Label>
                  <CustomInput type="select" onChange={onChange} id="ativo" name="ativo" value={values.ativo}>
                    <option value=""></option>
                    <option value="true">{i18n.t('tpOrder.edit.form.ative.true')}</option>
                    <option value="false">{i18n.t('tpOrder.edit.form.ative.false')}</option>
                  </CustomInput>
                </Col>

                <Col md={8}>
                  <FormGroup>
                    <Label> {i18n.t('tpOrder.edit.form.matGroup')} </Label>
                    <Select
                      placeholder="Selecione"
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      onChange={onChangeCat}
                      value={values.matGrupos}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      name="matGrupos"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={matGrupos}
                      formatGroupLabel={SelectGroupLabel}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label> {i18n.t('tpOrder.edit.form.qtdeOrdemCIF')} </Label>
                    <InputNumber onChange={onChange} name="qtdeOrdemCIF" id="qtdeOrdemCIF" value={values.qtdeOrdemCIF} />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label>Valor Mínimo</Label>
                    <InputReal onChange={onChange} name="vlMinPedido" id="vlMinPedido" value={values.vlMinPedido} />
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label> {i18n.t('tpOrder.edit.form.material')} </Label>

                    <AsyncSelect
                      placeholder="Digite para pesquisar"
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      name="materiais"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      loadOptions={onLoadMateriais}
                      onChange={onChangeCat}
                      value={values.materiais}
                      defaultOptions={materiais}
                      isClearable
                      isMulti
                    />
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label> {i18n.t('tpOrder.edit.form.segment')} </Label>
                    <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.segmentos} isMulti isClearable name="segmentos" className="basic-multi-select" classNamePrefix="select" options={segmentos} />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <InputCheck onChange={onChange} id="obrigaLote" name="obrigaLote" value={values.obrigaLote} label={i18n.t('tpOrder.edit.form.forceLot')} />
                  </FormGroup>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <InputCheck onChange={onChange} id="isBillOfMaterial" name="isBillOfMaterial" value={values.isBillOfMaterial} label={i18n.t('tpOrder.edit.form.isBillOfMaterial')} />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <CardTitle>Regra para cálculo de data de entrega</CardTitle>
          <Row>
            {values.obrigaLote ? (
              <Col md={12}>
                <Card className="main-card mb-2">
                  <CardBody>
                    <Label>Data de entrega definida no arquivo de lotes</Label>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <>
                <Col md={8}>
                  <Card className="main-card mb-2">
                    <CardBody>
                      <FormGroup>
                        <Nav tabs style={{ marginBottom: '0' }}>
                          <NavItem>
                            <NavLink
                              className={values.tpCalcEntrega === '1' ? 'active' : ''}
                              onClick={() => {
                                setValues({ ...values, tpCalcEntrega: '1' })
                              }}
                            >
                              Simples
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={values.tpCalcEntrega === '2' ? 'active' : ''}
                              onClick={() => {
                                setValues({ ...values, tpCalcEntrega: '2' })
                              }}
                            >
                              Avançada
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={values.tpCalcEntrega}>
                          <TabPane tabId="1">
                            <Card body>
                              <Row>
                                <Col md={3}>
                                  <FormGroup>
                                    <Label>
                                      {i18n.t('tpOrder.edit.form.daysForDelivery')} <Tooltip placement="auto" text="?" content={i18n.t('tpOrder.edit.form.daysForDeliveryH')} key="nrDiasPrevEntregaH" id="nrDiasPrevEntregaH" />
                                    </Label>
                                    <InputNumber onChange={onChange} name="nrDiasPrevEntrega" id="nrDiasPrevEntrega" value={values.nrDiasPrevEntrega} />
                                  </FormGroup>
                                </Col>

                                <Col md={3}>
                                  <FormGroup>
                                    <Label>{i18n.t('tpOrder.edit.form.dayWeekForDelivery')} </Label>
                                    <CustomInput type="select" onChange={onChange} id="diaSemana" name="diaSemana" value={values.diaSemana}>
                                      <option value=""></option>
                                      <option value="0">{i18n.t('layout.daysWeek.0')}</option>
                                      <option value="1">{i18n.t('layout.daysWeek.1')}</option>
                                      <option value="2">{i18n.t('layout.daysWeek.2')}</option>
                                      <option value="3">{i18n.t('layout.daysWeek.3')}</option>
                                      <option value="4">{i18n.t('layout.daysWeek.4')}</option>
                                      <option value="5">{i18n.t('layout.daysWeek.5')}</option>
                                      <option value="6">{i18n.t('layout.daysWeek.6')}</option>
                                    </CustomInput>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Card>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row>
                              <Col md={12}>
                                <Card body>
                                  <Col className="tb-report position-relative">
                                    <Table striped className="mb-4">
                                      <thead>
                                        <tr>
                                          <th>Pedidos enviados</th>
                                          <th>Até </th>
                                          <th>Será despachado </th>

                                          <th style={{ textAlign: 'right' }}></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {listCalcEntrega?.map((d, index) => (
                                          <tr key={index}>
                                            <td>{i18n.t(`layout.daysWeek.${d.diaSemanaPed}`)}</td>
                                            <td>{d.horaPed ? `${d.horaPed}:00` : ''}</td>
                                            <td>{i18n.t(`layout.daysWeek.${d.diaSemanaEnt}`)}</td>

                                            <td style={{ textAlign: 'right' }}>
                                              <Button
                                                className="btn btn-danger btn-sm"
                                                style={{ marginRight: '5px' }}
                                                onClick={() => {
                                                  onRemoveRegra(d.tpOrdemCalcEntId)
                                                }}
                                              >
                                                <BsFillTrashFill size={14} />
                                              </Button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Card>
                              </Col>

                              <Col md={12} className="mt-2">
                                <Card body>
                                  <Row>
                                    <Col md={9}>
                                      <FormGroup>
                                        <Label>Pedidos enviados *</Label>
                                        <CustomInput type="select" onChange={onChangeCalcEnt} id="diaSemanaPed" name="diaSemanaPed" value={valCalcEnt.diaSemanaPed}>
                                          <option value=""></option>
                                          <option value="0">{i18n.t('layout.daysWeek.0')}</option>
                                          <option value="1">{i18n.t('layout.daysWeek.1')}</option>
                                          <option value="2">{i18n.t('layout.daysWeek.2')}</option>
                                          <option value="3">{i18n.t('layout.daysWeek.3')}</option>
                                          <option value="4">{i18n.t('layout.daysWeek.4')}</option>
                                          <option value="5">{i18n.t('layout.daysWeek.5')}</option>
                                          <option value="6">{i18n.t('layout.daysWeek.6')}</option>
                                        </CustomInput>
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Até</Label>
                                        <CustomInput type="select" onChange={onChangeCalcEnt} id="horaPed" name="horaPed" value={valCalcEnt.horaPed}>
                                          <option value=""></option>
                                          <option value="0">00:00</option>
                                          <option value="1">01:00</option>
                                          <option value="2">02:00</option>
                                          <option value="3">03:00</option>
                                          <option value="4">04:00</option>
                                          <option value="5">05:00</option>
                                          <option value="6">06:00</option>
                                          <option value="7">07:00</option>
                                          <option value="8">08:00</option>
                                          <option value="9">09:00</option>
                                          <option value="10">10:00</option>
                                          <option value="11">11:00</option>
                                          <option value="12">12:00</option>
                                          <option value="13">13:00</option>
                                          <option value="14">14:00</option>
                                          <option value="15">15:00</option>
                                          <option value="16">16:00</option>
                                          <option value="17">17:00</option>
                                          <option value="18">18:00</option>
                                          <option value="19">19:00</option>
                                          <option value="20">20:00</option>
                                          <option value="21">21:00</option>
                                          <option value="22">22:00</option>
                                          <option value="23">23:00</option>
                                        </CustomInput>
                                      </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                      <FormGroup>
                                        <Label>Será despachado *</Label>
                                        <CustomInput type="select" onChange={onChangeCalcEnt} id="diaSemanaEnt" name="diaSemanaEnt" value={valCalcEnt.diaSemanaEnt}>
                                          <option value=""></option>
                                          <option value="0">{i18n.t('layout.daysWeek.0')}</option>
                                          <option value="1">{i18n.t('layout.daysWeek.1')}</option>
                                          <option value="2">{i18n.t('layout.daysWeek.2')}</option>
                                          <option value="3">{i18n.t('layout.daysWeek.3')}</option>
                                          <option value="4">{i18n.t('layout.daysWeek.4')}</option>
                                          <option value="5">{i18n.t('layout.daysWeek.5')}</option>
                                          <option value="6">{i18n.t('layout.daysWeek.6')}</option>
                                        </CustomInput>
                                      </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                      <FormGroup>
                                        <div>
                                          <Button type="button" onClick={onAddRegra} className="btn-block btn-lg" color="primary" style={{ marginRight: '5px' }}>
                                            Adicionar Regra
                                          </Button>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="main-card mb-2">
                    <CardBody>
                      <FormGroup>
                        <CardTitle>Exceções</CardTitle>
                        <Col md={12}>
                          <FormGroup>
                            <Label>
                              {i18n.t('tpOrder.edit.form.daysForDelivery')}
                              <Tooltip placement="auto" text="?" content={i18n.t('tpOrder.edit.form.daysForDeliveryH')} key="nrDiasPrevEntregaH" id="nrDiasPrevEntregaH" />
                            </Label>
                            <InputNumber onChange={onChange} name="diasExcecao" id="diasExcecao" value={values.diasExcecao} />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label> {i18n.t('tpOrder.edit.form.matGroup')} </Label>
                            <Select
                              placeholder="Selecione"
                              noOptionsMessage={() => 'Nenhuma opção disponível'}
                              onChange={onChangeCat}
                              value={values.diasExcecao > 0 ? values.matGruposExcecao : ''}
                              isMulti
                              isClearable
                              closeMenuOnSelect={false}
                              name="matGruposExcecao"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={matGrupos}
                              formatGroupLabel={SelectGroupLabel}
                              isDisabled={values.diasExcecao > 0 ? false : true}
                            />
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </ReactCSSTransitionGroup>
  )
}
