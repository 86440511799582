import React, { Fragment } from 'react'
import File from '../../Document/Query/File'
import { CardTitle, Row, Table } from 'reactstrap'

export default function Element({ files }) {
  return (
    <Fragment>
      <CardTitle style={{ display: 'flex', justifyContent: 'center', marginBottom: '0' }}>
        <span>Arquivos</span>
      </CardTitle>
      {files === undefined || files?.length === 0 ? (
        <Fragment></Fragment>
      ) : (
        <Row>
          <Table className="mb-2 mt-2">
            <thead>
              <tr>
                <th>Nome</th>
                <th style={{ width: '100px', textAlign: 'right' }}>Tamanho</th>
                <th style={{ width: '100px', textAlign: 'center' }}>Dh Upload</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {files.map((d) => (
                <File key={d.sysArquivoId} values={d} cardSize={12} />
              ))}
            </tbody>
          </Table>
        </Row>
      )}
    </Fragment>
  )
}
