import styled from 'styled-components'

export const Wrapper = styled.div`
  text-align: center;

  padding: 5px 0;
  font-size: 16px;
  color: var(--gray-dark);
  opacity: 0.7;
  text-align: center;
`
