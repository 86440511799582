import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, i18n } from '../../../Components'

import { BsEyeFill, BsPencil } from 'react-icons/bs'
import { Badge, Col, Table } from 'reactstrap'
import { OrdensGetRecentes } from '../../../Service/ApiService'

export default function Componente() {
  const [dataGrid, setDataGrid] = useState([])
  const Par_Acessos = useSelector((state) => state.Par_Acessos)

  const getBadge = (situacao) => {
    switch (situacao) {
      case '10':
        return 'warning'

      case '20':
        return 'focus'
      case '30':
        return 'focus'
      case '40':
        return 'focus'

      case '90':
        return 'success'
      case '99':
        return 'dark'
      default:
        return 'danger'
    }
  }

  async function loadGrid() {
    const response = await OrdensGetRecentes()
    if (response) setDataGrid(response)
  }

  useEffect(() => {
    loadGrid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function isAccess(codigo) {
    return Par_Acessos.some((f) => f.itens.some((i) => i.codigo === codigo))
  }

  return (
    <Fragment>
      <Col className="tb-report position-relative table-responsive">
        <Table striped>
          <thead>
            <tr>
              <th>{i18n.t('home.order.columns.myOrder')} </th>
              <th>{i18n.t('home.order.columns.date')}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t('home.order.columns.status')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataGrid.map((d) => (
              <tr key={d.ordemId}>
                <td>{d.pedCompra}</td>
                <td>{d.dhCadastro}</td>

                <td style={{ textAlign: 'center' }}>
                  <Badge className="btn-block mb-2 mr-2 badge" color={getBadge(d.situacao)}>
                    {d.nmSituacao}
                  </Badge>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <div style={{ textAlign: 'center' }}>
                    {d.situacao === '10' ? (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          window.location.href = '/#/Admin/Order/Edit/' + d.ordemId
                        }}
                        style={{ marginRight: '5px' }}
                        disabled={!isAccess('OrdemEdit')}
                      >
                        <BsPencil />
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-square btn-sm"
                        onClick={() => {
                          window.location.href = '/#/Admin/Order/Details/' + d.ordemId
                        }}
                        style={{ marginRight: '5px' }}
                      >
                        <BsEyeFill />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Fragment>
  )
}
