import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;

  background-color: var(--white);
`

export const Container = styled.div`
  border: 2px solid var(--gray);
  border-radius: 4px;

  margin: 5px 0;
  padding: 5px 10px;
  font-size: 14px;
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  :hover {
    background-color: var(--secondary);
    cursor: pointer;
  }
  > svg {
    color: #ff9933;
  }
`

export const CardName = styled.div`
  padding: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 400;
  font-size: 16px;
`

export const CardInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  padding: 5px 0;
  font-size: 12px;
`
