import React, { useEffect, useState } from 'react'
import { Select, i18n, toast, Bounce, InputCheck } from '../../Components'
import { Row, Col, FormGroup, CustomInput, Label, Input, Button } from 'reactstrap'

import { UsuarioAddOrUpdate, PerfilGet, UsuarioGetById } from '../../Service/ApiService'
import { getUser } from '../../Reducers/storageApp'

const initValues = { contaId: 0, nome: '', email: '', perfilId: '', idioma: '', fuso: '', ativo: '', isExecutivo: false }

export default function Element({ change, usuarioId, contaId }) {
  const [values, setValues] = useState()
  const [perfis, setPerfis] = useState()
  const [idiomas] = useState([
    { value: 'pt-BR', label: i18n.t('layout.header.Language.portuguese') },
    { value: 'en', label: i18n.t('layout.header.Language.english') },
    { value: 'es', label: i18n.t('layout.header.Language.Spanish') },
  ])

  async function loadPerfil(contaId, id) {
    var result = await PerfilGet()
    let _return = undefined
    let items = []
    if (result) {
      result.forEach((item) => {
        if (contaId === 1) {
          items.push({ value: item.perfilId, label: item.nome })
          if (id === item.perfilId) _return = { value: item.perfilId, label: item.nome }
        } else if (contaId !== 1 && item.isAdmin === false) {
          items.push({ value: item.perfilId, label: item.nome })
          if (id === item.perfilId) _return = { value: item.perfilId, label: item.nome }
        }
      })
    }
    setPerfis(items)

    return _return
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadValues() {
    const user = await getUser()
    let contaId2 = contaId ? contaId : user.contaId

    if (usuarioId) {
      var response = await UsuarioGetById(usuarioId)

      idiomas.forEach((element) => {
        if (element.value === response.idioma) response.idioma = element
      })

      response.perfilId = await loadPerfil(contaId2, response.perfilId)
      setValues(response)
    } else {
      setValues({ ...initValues, ativo: true, contaId: contaId2 })
      await loadPerfil(contaId2)
    }
  }

  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.nome || !values.email || !values.ativo) {
      toast(i18n.t('user.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    let _values = { ...values }
    //_values.usuarioId = parseInt(_values.usuarioId)
    _values.perfilId = !_values.perfilId ? undefined : _values.perfilId.value
    _values.idioma = !_values.idioma ? undefined : _values.idioma.value

    const response = await UsuarioAddOrUpdate(_values)
    if (response?.result) change()
    else toast(response?.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }

  return (
    <>
      {values && (
        <Row>
          <Col md={5}>
            <FormGroup>
              <Label> {i18n.t('user.edit.form.name')} * </Label>
              <Input type="text" onChange={onChange} value={values.nome} name="nome" id="nome" />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.email')} * </Label>
              <Input type="text" onChange={onChange} value={values.email} name="email" id="email" />
            </FormGroup>
          </Col>

          <Col md={2}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.status')} *</Label>
              <CustomInput type="select" onChange={onChange} value={values.ativo} id="ativo" name="ativo">
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </CustomInput>
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.profile')}</Label>
              <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.perfilId} name="perfilId" className="basic-multi-select" classNamePrefix="select" options={perfis} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.languange')}</Label>
              <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.idioma} name="idioma" className="basic-multi-select" classNamePrefix="select" options={idiomas} />
            </FormGroup>
          </Col>

          <Col md={4} style={{ display: 'flex', alignItems: 'center' }}>
            <InputCheck label="Executivo de vendas" onChange={onChange} value={values.isExecutivo} name="isExecutivo" />
          </Col>

          <Col md={12} className="d-block text-right">
            <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
              {i18n.t('user.add.form.buttonSave')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}
