import React from 'react'
import { ReactCSSTransitionGroup, i18n } from '../../Components'

import { Route, Routes } from 'react-router-dom'
import { Wrapper, CardLogo, CardBg, Content } from './styles'

import logo from '../../Assets/Images/logo.png'
import SingIn from './SingIn'
import Recover from './Recover'
import NewPass from './NewPass'

export default function Componente() {
  return (
    <Wrapper>
      <CardBg />
      <Content>
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <CardLogo>
            <img src={logo} alt="Logo_App" />
            <h1>{i18n.t('login.title')}</h1>
          </CardLogo>
          <Routes>
            <Route exact path="/" element={<SingIn />} />
            <Route path="/recover" element={<Recover />} />
            <Route path="/pass/:id" element={<NewPass />} />
          </Routes>
        </ReactCSSTransitionGroup>
      </Content>
    </Wrapper>
  )
}
