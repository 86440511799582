import React, { useState } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import { i18n, toast, Bounce } from '../../../../Components'
import { SysArquivoAddOrUpdateFolder } from '../../../../Service/ApiService'

const initValues = {
  nome: '',
  processo: 'AdminUpload',
  ativo: true,
  sysArquivoRefId: '',
}

export default function Element({ onChange, showModal, sysArquivoRefId }) {
  const [values, setValues] = useState({ ...initValues })
  library.add(fab, faSpinner)

  async function onSubmit() {
    if (!values.nome) {
      toast(i18n.t('account.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    let _values = { ...values, sysArquivoRefId }
    const response = await SysArquivoAddOrUpdateFolder(_values)
    if (response.result) {
      onChange()
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  function onChangeL(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  function onModal() {
    onChange()
  }

  return (
    <Modal isOpen={showModal} toggle={onModal}>
      <ModalHeader toggle={onModal}> Adicionar pasta </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>{i18n.t('document.add.form.folder')}</Label>
              <Input type="text" onChange={onChangeL} name="nome" id="nome" />
            </FormGroup>
          </Col>

          <Col md={12} className="d-block text-right">
            <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success" style={{ marginLeft: '10px' }}>
              Salvar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
