import React, { Fragment, useState, useEffect } from 'react'
import { Card, CardBody, Row, Col, Input, Table } from 'reactstrap'

import { useDispatch, PageTitle, i18n, ReactCSSTransitionGroup, useSelector } from '../../../Components'
import { SysArquivosGet } from '../../../Service/ApiService'

import Folder from './Folder'
import FolderAdd from './FolderAdd'

import File from './File'
import FileAdd from './FileAdd'
import { BtnFolder, BtnWhapper } from './styles'
import { getUser } from '../../../Reducers/storageApp'

const initialFilters = {
  sysArquivoRefId: 0,
  ativo: true,
}
function FormConsulta() {
  const { contaId } = getUser()
  const dispatch = useDispatch()
  const Par_Acessos = useSelector((state) => state.Par_Acessos)
  const folderOpen = useSelector((state) => state.FolderOpen)

  const [folders, setFolders] = useState([])
  const [confModal, setConfModal] = useState({ showModal: false })
  const [editAccess, setEditAccess] = useState(false)

  const [filters, setFilters] = useState(initialFilters)

  const [DataGrid, setDataGrid] = useState()

  async function loadSysArquivos(_filters = filters) {
    let filter = { ..._filters }
    if (contaId !== 1) {
      filter = { ...filter, contaId, publicos: true }
    }
    const response = await SysArquivosGet(filter)
    setDataGrid(response)
  }

  function onChange(ev) {
    const { name, value } = ev.target
    var _filter = {}
    if (value === '') {
      setFolders([])
      _filter = { ...filters, sysArquivoRefId: 0, [name]: null }
    } else _filter = { ...filters, sysArquivoRefId: null, [name]: value }

    setFilters(_filter)

    loadSysArquivos(_filter)
  }

  useEffect(() => {
    if (Par_Acessos) {
      const isAccess = Par_Acessos.some((f) => f.itens.some((i) => i.codigo === 'DocumentoCad'))
      setEditAccess(isAccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Par_Acessos])

  useEffect(() => {
    setTimeout(function () {
      if (folderOpen) {
        var _filter = { ...filters, sysArquivoRefId: folderOpen }
        setFilters(_filter)
        loadSysArquivos(_filter)

        dispatch({ type: 'FolderOpen', value: undefined })
      } else loadSysArquivos()
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onFolder(ev) {
    let _filter = { ...filters }
    if (ev) _filter = { ..._filter, sysArquivoRefId: ev.sysArquivoId, txtPesquisa: '' }

    setFilters(_filter)
    loadSysArquivos(_filter)

    let _folders = [...folders]
    _folders.push({ id: ev.sysArquivoId, nome: ev.nome })
    setFolders([..._folders])
  }

  function onFolderBack(ev) {
    let _folders = []
    if (ev !== 0)
      for (let index = 0; index < folders.length; index++) {
        if (folders[index].id === ev) {
          _folders.push(folders[index])
          break
        } else _folders.push(folders[index])
      }

    setFolders([..._folders])

    let _filter = { ...filters, sysArquivoRefId: ev }
    setFilters(_filter)
    loadSysArquivos(_filter)
  }

  function onModal(ev) {
    if (ev === false) setConfModal({ ...confModal, showModal: !confModal.showModal })
    loadSysArquivos()
    // if (files) loadFiles()
    // else loadFolders()
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('document.query.heading')} subheading={i18n.t('document.query.subheading')} icon="BsFolder2">
            {editAccess && (
              <Fragment>
                <FileAdd onChange={onModal} sysArquivoRefId={filters.sysArquivoRefId} />
                <FolderAdd onChange={onModal} sysArquivoRefId={filters.sysArquivoRefId} />
              </Fragment>
            )}
          </PageTitle>
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody className="table-responsive">
                <Row>
                  <Col md={9}>
                    <BtnWhapper>
                      <BtnFolder onClick={() => onFolderBack(0)}>Home</BtnFolder>
                      {folders.map((d) => (
                        <Fragment key={d.id}>
                          /<BtnFolder onClick={() => onFolderBack(d.id)}>{d.nome}</BtnFolder>
                        </Fragment>
                      ))}
                    </BtnWhapper>
                  </Col>
                  <Col md={3} className="d-block text-right">
                    <Input type="text" onChange={onChange} placeholder="Pesquisar" value={filters.txtPesquisa} name="txtPesquisa" id="txtPesquisa" />
                  </Col>
                </Row>

                {DataGrid === undefined ? (
                  <Fragment />
                ) : (
                  <Col className="tb-report position-relative table-responsive">
                    <Table className="mb-2 mt-2">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th style={{ width: '100px', textAlign: 'right' }}>Tamanho</th>
                          <th style={{ width: '100px', textAlign: 'center' }}>Dh Upload</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {DataGrid.map((d) => (
                          <Fragment key={d.sysArquivoId}>{d.tipo === 'Folder' ? <Folder key={d.sysArquivoId} onChange={onFolder} values={d} editAccess={editAccess} /> : <File key={d.sysArquivoId} values={d} editAccess={editAccess} />}</Fragment>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default FormConsulta
