import React, { useEffect, useState } from 'react'
import { i18n } from '../../../Components'
import { Card, CardBody, Row, Col, CardTitle, Table } from 'reactstrap'
import { MaskReal } from '../../../helpers/functions'

export default function Element({ obrigaLote, itens = [] }) {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let _total = 0
    itens.forEach((item) => {
      if (item.preco !== null) _total += item.qtde * item.preco
    })
    setTotal(_total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens])

  return (
    <Card className="main-card mb-2">
      <CardBody style={{ padding: '0.75rem' }}>
        <CardTitle style={{ display: 'flex' }}>
          <span>Itens</span>
        </CardTitle>

        <Row>
          <Col className="tb-report position-relative table-responsive">
            <Table striped className="mb-4">
              <thead>
                <tr>
                  <th>{i18n.t('order.itens.grid.materialId')} </th>
                  <th>{i18n.t('order.itens.grid.materialName')}</th>
                  <th>{i18n.t('order.itens.grid.unit')}</th>
                  <th style={{ textAlign: 'right' }}>{i18n.t('order.itens.grid.qtde')}</th>
                  <th style={{ textAlign: 'right' }}>{i18n.t('order.itens.grid.pending')}</th>
                  <th style={{ textAlign: 'right' }}>{i18n.t('order.itens.grid.unitValue')}</th>
                  <th style={{ textAlign: 'right' }}>{i18n.t('order.itens.grid.amount')}</th>
                  {obrigaLote === true && <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.lotNumber')}</th>}
                  <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.grid.lotAvailability')}</th>
                </tr>
              </thead>

              <tbody>
                {itens.map((d, index) => (
                  <tr key={`Item-${index}`}>
                    <td>{d.materialIdExt}</td>
                    <td>{d.nmMaterial}</td>
                    <td>{d.material.uniMedida}</td>
                    <td style={{ textAlign: 'right' }}>{d.qtde}</td>
                    <td style={{ textAlign: 'right' }}>{d.qtdePendente}</td>

                    <td style={{ textAlign: 'right' }}>{MaskReal(d.preco)}</td>
                    <td style={{ textAlign: 'right' }}>{d.preco != null ? MaskReal(d.qtde * d.preco) : ''}</td>
                    {obrigaLote === true && <td style={{ textAlign: 'center' }}>{d.nrLote}</td>}
                    <td style={{ textAlign: 'center' }}>{d.dtPrevEntrega}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="7" style={{ marginTop: '10px', textAlign: 'right' }}>
                    <strong> {MaskReal(total)}</strong>
                  </td>
                  {obrigaLote === true && <td></td>}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
