import styled from 'styled-components'

export const SubTitle = styled.div`
  padding: 8px 0;
  text-align: center;

  font-weight: 600;
  font-size: 14px;
`

export const ItemContact = styled.div`
  padding: 7px 5px;

  border-bottom: 2px solid #e0e0e0;

  > span {
    margin-left: 6px;
  }
`

export const LinkContact = styled.a`
  text-decoration: none !important;
`

export const TimeContact = styled.div`
  padding: 5px 2px;

  > span {
    margin-left: 6px;
    font-size: 12px;
  }
`

export const AddressContact = styled.div`
  margin: 10px 0 10px;
  text-align: center;
`
