import React, { Component } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import * as ReactIcons from 'react-icons/bs'

const Icon = (props) => {
  const { iconName, size, color } = props
  const icon = React.createElement(ReactIcons[iconName])
  return <div style={{ display: 'flex', fontSize: size, color: color }}>{icon}</div>
}

class PageTitle extends Component {
  render() {
    let {
      enablePageTitleIcon = true,
      enablePageTitleSubheading = true,

      heading,
      subheading,
      children,
      icon = 'BsWindow',
    } = this.props

    return (
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className={cx('page-title-icon', { 'd-none': !enablePageTitleIcon })}>
              <div style={{ margin: 'auto' }}>
                <Icon iconName={icon} size={28} color="var(--primary)" />
              </div>
            </div>
            <div>
              {heading}
              <div className={cx('page-title-subheading', { 'd-none': !enablePageTitleSubheading })}>{subheading}</div>
            </div>
          </div>
          {children && <div className="page-title-actions">{children}</div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.enablePageTitleIcon,
  enablePageTitleSubheading: state.enablePageTitleSubheading,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle)
