/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalBody, ModalHeader, Col, ModalFooter, Button } from 'reactstrap'

export default function Element({ onChange, showModal }) {
  library.add(fab, faSpinner)

  function onModal() {
    onChange()
  }

  return (
    <Modal isOpen={showModal} size="lg" toggle={onModal} style={{ width: '100%' }}>
      <ModalHeader toggle={onModal}>Confirmação de envio</ModalHeader>
      <ModalBody style={{ background: 'var(--warning)' }}>
        <Col md={12}>
          <h4 style={{ color: 'white' }}>Atenção</h4>
          <p style={{ color: 'white' }}>
            Você utilizou o limite de pedidos CIF. <br /> Esse pedido, será enviado como frete FOB, gerando cobrança adicional de transporte
          </p>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="btn btn-secondary btn-lg btn-wide" onClick={onModal}>
          Cancelar
        </Button>
        <Button color="success" className="btn btn-success btn-lg btn-wide" onClick={() => onChange(true)}>
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
