import { CardHeader } from 'reactstrap'
import styled from 'styled-components'

export const CCardHeader = styled(CardHeader)`
  background-color: #e7f9fe;
  height: 2.5rem;

  > div:nth-child(2) {
    margin: 0 0 0 auto;
    > span {
      margin-right: 10px;
    }
  }
`
export const HeaderTitle = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
  }
`
export const CardMsg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`
