import { useLocation } from 'react-router-dom'

export default function NoMatch() {
  let location = useLocation()

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <h3>Ops! Página não encontrada!</h3>
      <h3>
        <code>{location.pathname}</code>
      </h3>
    </div>
  )
}
