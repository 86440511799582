import { createStore } from 'redux'

function storeAppL(
  state = {
    Par_Menu: {},
    Par_Acessos: '',
    FolderOpen: '', //Pasta selecionada
    OrdemId: '',
    contas: [],

    enableMobileMenu: false,
    enableClosedSidebar: false,
    enableMobileMenuSmall: false,
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'Par_Menu':
      return {
        ...state,
        Par_Menu: action.value,
      }
    case 'Par_Acessos':
      return {
        ...state,
        Par_Acessos: action.value,
      }
    case 'FolderOpen':
      return {
        ...state,
        FolderOpen: action.value,
      }
    case 'OrdemId':
      return {
        ...state,
        OrdemId: action.value,
      }
    case 'contas':
      return { ...state, contas: action.value }

    case 'enableMobileMenu':
      return {
        ...state,
        enableMobileMenu: action.value,
      }
    case 'enableClosedSidebar':
      return {
        ...state,
        enableClosedSidebar: action.value,
      }
    case 'enableMobileMenuSmall':
      return {
        ...state,
        enableMobileMenuSmall: action.value,
      }
  }
  return state
}

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('stateTemp')

    if (serializedState === null) {
      return {
        Par_Menu: {},
        Par_Acessos: '',
        FolderOpen: '',
        OrdemId: '',
      }
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('stateTemp', serializedState)
  } catch (err) {
    console.error(err)
  }
}

const persistedState = loadState()
const store = createStore(storeAppL, persistedState)
store.subscribe(() => {
  saveState(store.getState())
})

export default store
