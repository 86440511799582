export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = Math.round((bytes / 1073741824).toFixed(2), 0) + ' GB'
  } else if (bytes >= 1048576) {
    bytes = Math.round((bytes / 1048576).toFixed(2), 0) + ' MB'
  } else if (bytes >= 1024) {
    bytes = Math.round((bytes / 1024).toFixed(2), 0) + ' KB'
  } else if (bytes > 1) {
    bytes = bytes + ' bytes'
  } else if (bytes === 1) {
    bytes = bytes + ' byte'
  } else {
    bytes = '0 bytes'
  }
  return bytes
}

export function MaskPhone(value) {
  if (value === undefined || value === null) return

  if (value.replace(/\D/g, '').length > 10) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(\d{4})\d+?$/, '$1')
  } else {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})\d+?$/, '$1')
  }
}
export function MaskReal(value) {
  var valueFloat = parseFloat(value).toFixed(2)

  var tmp = valueFloat
    .toString()
    .replace(/\D/g, '')
    .replace(/([0-9]{2})$/g, ',$1')
  if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')

  return 'R$ ' + tmp
}

export function MaskCpfCnpj(value) {
  if (value === undefined || value === null) return

  if (value.length <= 11 || (value.length === 14 && value.includes('.'))) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  } else {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }
}
export function abbreviateNumber(number) {
  try {
    var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

    var tier = (Math.log10(Math.abs(number)) / 3) | 0
    if (tier == 0) return number.toFixed(1)
    var suffix = SI_SYMBOL[tier]
    var scale = Math.pow(10, tier * 3)
    var scaled = number / scale
    return scaled.toFixed(1) + suffix
  } catch {
    console.error(number)
    return number
  }
}

export function normaliseValue(value, decimals = 2) {
  try {
    if (!value) return ''
    if (value === '.') return (value = '0.')
    var regex = decimals > 0 ? new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`) : new RegExp(`^-?\\d+(?:\\.\\d{0})?`)

    const decimalsNumber = value.toString().match(regex)[0]

    let parsed = parseFloat(decimalsNumber).toFixed(2)
    if (isNaN(parsed)) return '0'

    parsed = parsed.replace('.', ',')

    if (parsed?.length == 7) return parsed.replace(/(\d{1})(\d)/, '$1.$2')
    if (parsed?.length == 8) return parsed.replace(/(\d{2})(\d)/, '$1.$2')
    if (parsed?.length == 9) return parsed.replace(/(\d{3})(\d)/, '$1.$2')

    return parsed
  } catch {
    return ''
  }
}
