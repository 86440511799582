import React, { Fragment, useState, useEffect } from 'react'
import ReactDatatable from '@ashvin27/react-datatable'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { i18n } from '../../../translate/i18n'

import { Card, CardBody, Button } from 'reactstrap'

import PageTitle from '../../../Layout/AppMain/PageTitle'
import { TpOrdemGet } from '../../../Service/ApiService'
import { BsPencil, BsPlus } from 'react-icons/bs'

function FormConsulta() {
  const [DataGrid, setDataGrid] = useState()

  const columns = [
    { key: 'tpOrdemIdExt', text: i18n.t('tpOrder.query.columns.codeExt'), sortable: true },
    { key: 'nome', text: i18n.t('tpOrder.query.columns.name'), sortable: true },
    { key: 'qtdeOrdemCIF', text: i18n.t('tpOrder.query.columns.qtdeOrdemCIF'), align: 'left', sortable: true },
    { key: 'isBillOfMaterial', text: i18n.t('tpOrder.query.columns.isBillOfMaterial'), align: 'left', sortable: true },

    { key: 'ativo', text: i18n.t('tpOrder.query.columns.status'), align: 'left', sortable: true },
    {
      key: 'action',
      text: '#',
      className: 'action',
      width: 40,
      align: 'left',
      sortable: false,
      cell: (record) => {
        return (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              window.location.href = '/#/Admin/TpOrder/Edit/' + record.tpOrdemId
            }}
          >
            <BsPencil />
          </button>
        )
      },
    },
  ]

  const config = {
    key_column: 'tpOrdemId',
    page_size: 10,
    length_menu: [10, 20, 50],
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadData() {
    const response = await TpOrdemGet()
    setDataGrid(response)
  }
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('tpOrder.query.heading')} subheading={i18n.t('tpOrder.query.subheading')}>
            <Button
              type="button"
              onClick={() => {
                window.location.href = `/#/admin/TpOrder/Add`
              }}
              className="btn-pill"
              color="success"
              style={{ marginRight: '15px' }}
            >
              <BsPlus size={18} />
            </Button>
          </PageTitle>
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody className="table-responsive">
                <ReactDatatable config={config} records={DataGrid} columns={columns} />
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default FormConsulta
