import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Collapse, Button, Badge, Col, FormGroup, Input } from 'reactstrap'
import { PageTitle, ReactCSSTransitionGroup, i18n } from '../../Components'
import { BsFillCaretDownFill, BsFillCaretUpFill, BsPlus, BsPencil } from 'react-icons/bs'
import ModalForm from './ModalForm'
import { FaqGet } from '../../Service/ApiService'
import { CCardHeader, HeaderTitle, CardMsg } from './styles'
import { getUser } from '../../Reducers/storageApp'

export default function FormCadastro() {
  const { contaId } = getUser()
  const [cardId, setCardId] = useState(0)
  const [DataGrid, setDataGrid] = useState()
  const [values, setValues] = useState({ txtPesquisa: '', situacao: ['P'] })

  const [confModal, setConfModal] = useState({
    showModal: false,
    faqId: undefined,
  })

  const getBadge = (situacao) => {
    switch (situacao) {
      case 'R':
        return 'warning'
      case 'P':
        return 'success'
      case 'I':
        return 'secondary'
      default:
        return 'danger'
    }
  }
  async function loadData(filters = values) {
    setCardId(0)
    if (contaId === 1) filters.situacao = ['P', 'R']
    const response = await FaqGet(filters)
    setDataGrid(response)
  }
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onCardId(index) {
    if (index === cardId) setCardId(0)
    else setCardId(index)
  }
  function onModal() {
    loadData()
    setConfModal({ ...confModal, showModal: !confModal.showModal })
  }
  function onEditItem(faqId) {
    setConfModal({ ...confModal, showModal: !confModal.showModal, faqId })
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })

    if (value.length > 3 || value.length === 0) {
      loadData({ ...values, [name]: value })
    }
  }

  return (
    <Fragment>
      <ModalForm onChange={onModal} showModal={confModal.showModal} faqId={confModal.faqId} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Perguntas frequentes" subheading="Aqui você encontra, as perguntas mais recorrentes e suas respostas" icon="BsQuestionLg">
            {contaId === 1 && (
              <Button
                type="button"
                onClick={() => {
                  setConfModal({ ...confModal, showModal: true, faqId: undefined })
                }}
                className="btn-pill"
                color="success"
                style={{ marginRight: '15px' }}
              >
                <BsPlus size={18} />
                <span style={{ marginRight: '5px' }}>{i18n.t('order.query.buttonAdd')}</span>
              </Button>
            )}
          </PageTitle>

          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <Col md={12}>
                  <FormGroup>
                    <h2>O que está procurando?</h2>
                    <Input type="text" onChange={onChange} value={values.txtPesquisa} name="txtPesquisa" id="txtPesquisa" />
                  </FormGroup>
                </Col>
              </CardBody>
            </Card>
            {DataGrid?.length > 0 && (
              <Fragment>
                {DataGrid.map((d, index) => (
                  <Card key={d.faqId} className="main-card mb-2">
                    <CCardHeader>
                      <HeaderTitle onClick={() => onCardId(index + 1)}>
                        {cardId === index + 1 ? <BsFillCaretUpFill size={18} /> : <BsFillCaretDownFill size={18} />}
                        <span className="font-weight-bold">{d.titulo}</span>
                      </HeaderTitle>
                      {contaId === 1 && (
                        <div>
                          <Badge className={`mb-2 mr-2 badge badge-pill badge-${getBadge(d.situacao)}`}>{d.situacao === 'P' ? 'Publicado' : 'Rascunho'}</Badge>

                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              onEditItem(d.faqId)
                            }}
                            style={{ marginRight: '5px' }}
                          >
                            <BsPencil />
                          </button>
                        </div>
                      )}
                    </CCardHeader>
                    <Collapse isOpen={cardId === index + 1 ? true : false}>
                      <CardBody>
                        <div dangerouslySetInnerHTML={{ __html: d?.conteudo }} />
                      </CardBody>
                    </Collapse>
                  </Card>
                ))}
              </Fragment>
            )}

            {DataGrid?.length === 0 && values.txtPesquisa && (
              <CardMsg>
                <h2>Ops, nenhum registro encontrado!</h2>
                <br />
                <h4>
                  <a style={{ textDecoration: 'none' }} href="/admin/contact">
                    Você pode enviar a sua pergunta para nosso time, clicando aqui
                  </a>
                </h4>
              </CardMsg>
            )}
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
