import React, { Fragment, useState, useEffect } from 'react'
import { ReactDatatable, ReactCSSTransitionGroup, toast, Bounce, i18n, useParams } from '../../../../Components'
import { MdOutlineAddLink, MdOutlineLinkOff } from 'react-icons/md'
import { Button, Card, CardBody, Col } from 'reactstrap'

import { ParticipantesGet, RemoverLinkConta } from '../../../../Service/ApiService'
import ModalCustomer from './Modal'

export default function FormUsuario() {
  const { id } = useParams()
  const [DataGrid, setDataGrid] = useState()
  const [confModal, setConfModal] = useState({ showModal: false, contaId: id })

  async function onRemoverLink(id) {
    var response = await RemoverLinkConta(id)
    if (response.result) {
      loadGrid()
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  const columns = [
    { key: 'custId', text: i18n.t('account.edit.customers.columns.custId'), sortable: true },
    { key: 'nomeFantasia', text: i18n.t('account.edit.customers.columns.name'), sortable: true },
    { key: 'endereco', text: i18n.t('account.edit.customers.columns.address'), align: 'left', sortable: true },
    { key: 'situacao', text: i18n.t('account.edit.customers.columns.status'), align: 'left', sortable: true },
    {
      key: 'bloqCredito',
      text: i18n.t('account.edit.customers.columns.blocFinanc'),
      align: 'left',
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <span className={`badge badge-pill ${record.bloqCredito === 'Sim' ? 'badge-danger' : 'badge-success'}`} style={{ margin: '0 2px' }}>
              {record.bloqCredito}
            </span>
          </Fragment>
        )
      },
    },
    {
      key: 'action',
      text: '#',
      className: 'action',
      width: 40,
      align: 'left',
      sortable: false,
      cell: (record) => {
        return (
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              onRemoverLink(record.participanteId)
            }}
          >
            <MdOutlineLinkOff size={16} />
          </button>
        )
      },
    },
  ]
  const config = {
    key_column: 'participanteId',
    page_size: 20,
    length_menu: [10, 20, 50],
    show_filter: false,
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadGrid() {
    const response = await ParticipantesGet({ contaId: id })
    setDataGrid(response)
  }

  useEffect(() => {
    loadGrid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onModal() {
    setConfModal({ ...confModal, showModal: !confModal.showModal })
    loadGrid()
  }

  return (
    <Fragment>
      <ModalCustomer change={onModal} showModal={confModal.showModal} contaId={confModal.contaId} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <Card className="main-card mb-2">
          <CardBody>
            <Col md={12} className="d-block text-right">
              <Button type="button" onClick={() => setConfModal({ ...confModal, showModal: true })} className="btn-pill" color="success" style={{ marginRight: '15px', zIndex: '1' }}>
                <MdOutlineAddLink size={18} />
              </Button>
            </Col>
            <Col md={12} style={{ marginTop: '-30px' }}>
              <ReactDatatable config={config} records={DataGrid} columns={columns} />
            </Col>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
