import moment from 'moment'
import { Button, CardBody, CardTitle, Col, Progress, Row, Table } from 'reactstrap'
import { MaskCpfCnpj, MaskReal } from '../../../../helpers/functions'
import { BsPlus } from 'react-icons/bs'
import { CCard, CCardTitle, StatusBand } from './styles'
import { useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { ContractItem_IsEnabled } from '../../../../Service/BizService'

export default function Element({ value }) {
  const [isOpen, setIsOpen] = useState(false)
  function getBgColor() {
    if (value.situacao === '10') return 'var(--info)'
    else if (value.situacao === '20') return 'var(--success)'
    else if (value.situacao === '30') return 'var(--danger)'
  }

  return (
    <CCard className="main-card mb-2">
      <StatusBand color={getBgColor()} />
      <CardBody className="table-responsive">
        <Row style={{ alignItems: 'center' }}>
          <Col md={7}>
            <CardTitle style={{ display: 'flex' }}>
              <span>Dados do contrato</span>
            </CardTitle>
          </Col>
          <Col md={3}>
            <Col md={12}>
              Vigencia:
              <strong>{moment(value.dtVigenciaIni).format('DD/MM/YYYY')}</strong> até
              <strong> {moment(value.dtVigenciaFim).format('DD/MM/YYYY')}</strong>
            </Col>
            {value.situacao === '10' && <Col md={12}>O contrato entra em vigencia em {moment(value.dtVigenciaIni).diff(moment(), 'days')} dias</Col>}
            {value.situacao === '20' && (
              <Col style={{ maxWidth: '235px' }} md={12}>
                <Progress color="warning" animated value={Math.round(((moment(value.dtVigenciaFim).diff(moment(value.dtVigenciaIni), 'days') - moment(value.dtVigenciaFim).diff(moment(), 'days')) / moment(value.dtVigenciaFim).diff(moment(value.dtVigenciaIni), 'days')) * 100, 0)}>
                  Restam {moment(value.dtVigenciaFim).diff(moment(), 'days')} dias
                </Progress>
              </Col>
            )}
            {value.situacao === '10' && <Col md={12}>O contrato encerrou há {moment(value.dtVigenciaFim).diff(moment(), 'days')} dias</Col>}
          </Col>
          <Col className="d-block text-right" md={2}>
            {value.situacao === '20' && (
              <Button
                type="button"
                className="btn-pill"
                color="success"
                id="btnPartPesquisar"
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  window.location.href = `/#/admin/Contract/OrderAdd/${value.contratoId}`
                }}
              >
                <BsPlus size={18} /> Add Pedidos
              </Button>
            )}
          </Col>
        </Row>

        <Row style={{ marginTop: '5px' }}>
          <Col md={4}>
            <Col md={12}>
              Nr Contrato: <strong> {value.numeroOV} </strong>
            </Col>
            <Col md={12}>
              Dt Pedido: <strong> {moment(value.dtPedido).format('DD/MM/YYYY')} </strong>
            </Col>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={12}>
                Empresa:{' '}
                <strong>
                  {MaskCpfCnpj(value.cpfCnpj)} - {value.participanteNm}
                </strong>
              </Col>

              <Col md={12}>
                Descrição: <strong> {value.descricao} </strong>
              </Col>
            </Row>
          </Col>
        </Row>

        <CCardTitle className="mt-3" style={{ display: 'flex' }}>
          <Button
            type="button"
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            className="btn-transition btn-outline-primary btn-sm"
            color="white"
            id="btnPartPesquisar"
          >
            {isOpen ? <MdExpandLess size={22} /> : <MdExpandMore size={22} />}
          </Button>

          <span>Itens do contrato</span>
        </CCardTitle>

        {isOpen && (
          <Row>
            <Col md={12} className="table-responsive">
              <Table striped className="mb-4">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Material</th>
                    <th>Tipo</th>

                    <th style={{ width: '100px', textAlign: 'right' }}>Qtde</th>
                    <th style={{ width: '100px', textAlign: 'right' }}>Saldo</th>
                    <th style={{ width: '150px', textAlign: 'right' }}>Preço Unitário</th>
                    <th>Utilização</th>
                  </tr>
                </thead>
                <tbody>
                  {value.itens.map((d, index) => (
                    <tr key={`item_${value.contratoId}_${index}`} style={{ opacity: ContractItem_IsEnabled(d.tipo) ? '1' : '0.7' }}>
                      <td>{d.materialIdExt}</td>
                      <td>{d.materialNm}</td>
                      <td>{d.tipo}</td>
                      <td style={{ width: '100px', textAlign: 'right' }}>{d.qtde}</td>
                      <td style={{ width: '100px', textAlign: 'right' }}>{d.saldo}</td>
                      <td style={{ width: '150px', textAlign: 'right' }}>{MaskReal(d.preco)}</td>
                      <td style={{ textAlign: 'center' }}>
                        {ContractItem_IsEnabled(d.tipo) && (
                          <Progress color="warning" animated value={Math.round(((d.qtde - d.saldo) / d.qtde) * 100, 0)}>
                            {Math.round(((d.qtde - d.saldo) / d.qtde) * 100, 0)}%
                          </Progress>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </CardBody>
    </CCard>
  )
}
