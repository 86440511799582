import styled from 'styled-components'

export const CardImg = styled.div`
  display: flex;
  justify-content: center;
`
export const ImgProfile = styled.img`
  margin: 0 auto;
  width: 100px;
  height: 100px;
  padding: 3px;
  border: 3px solid #d2d6de;
  border-radius: 50%;
`
export const Name = styled.h3`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const CardDesc = styled.div`
  padding: 0 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-bottom: 1px solid #e9ecef;
`
export const DescName = styled.p`
  font-size: 15px;
  align-items: center;
  display: flex;
  margin-bottom: 0;
  > span {
    margin-left: 8px;
    font-weight: bold;
  }
`
export const DescValue = styled.p`
  margin-left: 6px;
`
