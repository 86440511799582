import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, PageTitle, ReactCSSTransitionGroup, useSelector, i18n, useNavigate, useParams } from '../../../Components'

import { MdContentCopy, MdUndo } from 'react-icons/md'
import { Card, CardBody, Row, Col, Button, CardTitle } from 'reactstrap'
import { OrdemGetDetails } from '../../../Service/ApiService'
import Itens from './Item'
import Timeline from './Timeline'
import Remessas from './Remessas'
import Files from './Files'
import Financial from './Financial'
import ModalAltStatus from './ModalAltStatus'
import { getUser } from '../../../Reducers/storageApp'

const initValues = { ordemId: 0, ordemIdExt: '', ContaId: '', tpOrdem: '', participante: '', endParticipante: '', nmParticipante: '', itens: [], observacao: '', pedCompra: '', nmTpOrdem: '', nmUsuario: '' }

export default function FormCadastro() {
  const user = getUser()
  const { id } = useParams()
  const Par_Acessos = useSelector((state) => state.Par_Acessos)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [values, setValues] = useState(initValues)
  const [steps, setSteps] = useState()
  const [modAltStatus, setModAltStatus] = useState(false)

  async function loadValues() {
    const response = await OrdemGetDetails(id)
    var _value = { ...initValues, ...response.ordem }

    let steps = []
    if (_value.situacao > 10) steps.push({ label: 'Ordem enviada', date: _value.dhAtualizacao ? _value.dhAtualizacao : _value.dhCadastro })
    else steps.push({ label: 'Ordem enviada', date: '' })
    if (_value.dhCadastroExt) steps.push({ label: 'Ordem Confirmada', date: _value.dhCadastroExt })
    else steps.push({ label: 'Ordem Confirmada', date: '' })

    setSteps(steps)

    if (!_value.ordemIdExt) _value.ordemIdExt = ''
    _value.itens = response.itens
    _value.remessas = response.remessas
    _value.arquivos = response.arquivos
    _value.titulos = response.titulos

    setValues(_value)
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onItem(ev) {
    let _itens = values.itens
    _itens.push({ ...ev })
    setValues({ ...values, itens: _itens })
  }

  async function onCopy() {
    dispatch({ type: 'OrdemId', value: values.ordemId })
    window.location.href = `/#/admin/Order/Add`
  }
  async function onModAltStatus(ev) {
    if (ev) window.location.href = `/#/admin/Order/Query`
    else setModAltStatus(false)
  }

  function isAccess(codigo) {
    return Par_Acessos.some((f) => f.itens.some((i) => i.codigo === codigo))
  }

  return (
    <Fragment>
      <ModalAltStatus showModal={modAltStatus} ordemId={id} onChange={onModAltStatus} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('order.details.heading')} subheading={i18n.t('order.details.subheading')} icon="BsFillCartFill">
            {isAccess('OrdemAltSit') && user?.tpVenda === 'I' && (
              <Button type="button" onClick={() => setModAltStatus(true)} className="btn-pill" color="danger" style={{ marginRight: '5px' }}>
                <MdUndo /> {i18n.t('order.details.form.buttonAltStatus')}
              </Button>
            )}
            {user?.tpVenda === 'I' && (
              <Button type="button" onClick={onCopy} className="btn-pill" color="info" style={{ marginRight: '5px' }} disabled={!isAccess('OrdemCad')}>
                <MdContentCopy /> {i18n.t('order.edit.form.buttonCopy')}
              </Button>
            )}
            <Button type="button" onClick={() => navigate(-1)} className="btn-pill" color="secondary" style={{ marginRight: '5px' }}>
              {i18n.t('layout.btnGoBack')}
            </Button>
          </PageTitle>
          <div className="page-content">
            <Card className="main-card mb-1">
              <CardBody style={{ padding: '0.75rem' }}>
                <CardTitle style={{ display: 'flex' }}>
                  <span>Dados do pedido</span>
                </CardTitle>
                <Row>
                  <Col md={4}>
                    <Col md={12}>
                      {i18n.t('order.details.form.nrOv')}: <strong> {values.ordemIdExt} </strong>
                    </Col>
                    <Col md={12}>
                      {i18n.t('order.edit.form.myOrder')}: <strong>{values.pedCompra}</strong>
                    </Col>
                    <Col md={12}>
                      {i18n.t('order.details.form.buyer')}: <strong>{values.nmUsuario}</strong>
                    </Col>
                  </Col>

                  <Col md={8}>
                    <Col md={12}>
                      {i18n.t('order.details.form.tpOrder')}:<strong> {values.nmTpOrdem}</strong>
                    </Col>
                    <Col md={12}>
                      {i18n.t('order.details.form.customer')}: <strong> {values.nmParticipante}</strong>
                    </Col>
                    <Col md={12}>
                      {i18n.t('order.details.form.customerEnd')}:<strong> {values.endParticipante}</strong>
                    </Col>
                    <Col md={12}>
                      {i18n.t('order.details.form.observation')}: <strong> {values.observacao}</strong>
                    </Col>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Itens onChange={onItem} tpOrdemId={values.tpOrdem?.value} itens={values.itens} obrigaLote={values.obrigaLote} />

            <Card className="main-card mb-2">
              <CardBody style={{ padding: '0.75rem' }}>
                <Row>
                  <Col md={4} style={{ margin: 'auto' }}>
                    <Timeline steps={steps} />
                  </Col>
                  <Col md={8}>
                    <Remessas values={values.remessas} />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              <Col md={6} style={{ paddingLeft: '0' }}>
                <Card className="main-card mb-2">
                  <CardBody style={{ padding: '0.75rem' }}>
                    <Files files={values.arquivos} />
                  </CardBody>
                </Card>
              </Col>

              <Col md={6} style={{ paddingRight: '0' }}>
                <Card className="main-card mb-2">
                  <CardBody style={{ padding: '0.75rem' }}>
                    <Financial titulos={values.titulos} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
