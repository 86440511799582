import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;

  background-color: var(--white);
  /* > div {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
`
export const CardBg = styled.div`
  width: 100%;

  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/bg_login.png');

  background-position: center;
  height: 100vh;
  background-size: cover;

  @media (max-width: 688px) {
    display: none;
  }
`
export const Content = styled.div`
  width: 568px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);

  padding: 0 40px 10px 40px;

  @media (max-width: 688px) {
    width: 100%;
    margin: auto;
    padding: 0 20px 10px 20px;
  }
`
export const CardLogo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > img {
    margin: 40px auto;
    width: 200px;
  }

  > h1 {
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    color: var(--gray-dark);
    text-align: center;
  }
`
export const ContainerForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Input = styled.input`
  width: 100%;
  height: 32px;
  margin: 15px 0;
  padding: 18.5px 14px;

  color: var(--gray-dark);
  border: 0.5px solid var(--gray-dark);
  border-radius: 4px;
  opacity: 1;
  font-size: 1.2rem;

  ::placeholder {
    opacity: 1;
  }
`
export const Button = styled.button`
  width: 100%;
  padding: 8px 0;

  margin: 0 0 10px auto;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white);
  background: var(--blue) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 688px) {
    width: 100%;
  }
`
export const LbPassword = styled.label`
  width: 100%;
  margin-top: -10px;
  color: red;
`

export const ButtonAzure = styled.button`
  width: 100%;
  padding: 8px 0;

  margin: 0 0 10px auto;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white);
  background: #00bdf1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
  > svg {
    margin: 0 10px 0 0;
  }

  @media (max-width: 688px) {
    width: 100%;
  }
`

export const Link = styled.a`
  color: inherit;
  font-size: 1.2rem;
  color: var(--blue);
  text-align: right;
`

export const FooterLogin = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--gray-dark);
  font-weight: 100;

  > div:nth-child(1) {
    margin-bottom: 8px;
  }
`

export const Seperator = styled.div`
  float: left;
  width: 100%;
  border-top: 1px solid #ccc;
  text-align: center;
  margin: 50px 0 0;

  > b {
    width: 40px;
    height: 40px;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    background: #fff;
    display: inline-block;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: relative;
    top: -22px;
    z-index: 1;
  }
`
