import React, { Fragment, useState } from 'react'
import { Button, Card, CardBody, Col, FormGroup, Table } from 'reactstrap'
import { PageTitle, ReactCSSTransitionGroup, toast, Bounce } from '../../Components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import Dropzone from 'react-dropzone'
import { DropContainer } from '../Document/Add/styles'
import axios from 'axios'

function FormConsulta() {
  const [dataGrid, setDataGrid] = useState()

  const [msg, setMsg] = useState({ id: 1, message: '' })

  library.add(fab, faSpinner)

  async function handleDrop(ev) {
    if (ev.length !== 1) {
      toast('Permitido apenas um arquivo', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (ev[0].name.substring(ev[0].name.length - 4).toUpperCase() !== '.CSV') {
      toast('O arquivo precisa estar no formato CSV', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    processUpload(ev[0])
  }

  async function processUpload(file) {
    setMsg({ id: 2, message: '' })
    var formdata = new FormData()
    formdata.append('File', file, file.name)

    const api = axios.create({ baseURL: process.env.REACT_APP_API })
    await api
      .post('/Bom/Add', formdata)
      .then((response) => {
        setMsg({ id: 3, message: 'Success' })
        if (response.data.result) setDataGrid(response.data.logs)
        else toast(response.data.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      })
      .catch(() => {
        setMsg({ id: 1, message: 'error' })
        toast('Ocorreu um erro na requisição', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      })
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Upload de Lista de materiais" subheading="Atualização de dados de lista de materiais" />
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <div>
                  {
                    {
                      1: (
                        <Col md="12" className="text-center">
                          <FormGroup>
                            <Dropzone onDrop={handleDrop}>
                              {({ getRootProps, getInputProps }) => (
                                <DropContainer {...getRootProps({ className: 'dropzone' })}>
                                  <input {...getInputProps()} />
                                  <p>Arraste e solte o arquivo CSV de Bill of Material ou clique para selecionar</p>
                                </DropContainer>
                              )}
                            </Dropzone>
                          </FormGroup>
                        </Col>
                      ),
                      2: (
                        <Col md="12" className="text-center">
                          <FontAwesomeIcon icon={['fas', 'spinner']} pulse fixedWidth size="4x" />
                        </Col>
                      ),
                      3: (
                        <Col md="12" className="text-center">
                          {dataGrid === undefined ? (
                            <Fragment />
                          ) : (
                            <Fragment>
                              <Col className="tb-report position-relative table-responsive">
                                <Table striped className="mb-4">
                                  <thead>
                                    <tr>
                                      <th style={{ textAlign: 'left' }}>Nome</th>
                                      {/*<th>Subgrupo</th>
                                      <th>Nr Lote</th> */}
                                      <th>Log</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataGrid.map((d, index) => (
                                      <tr
                                        key={index}
                                        style={{
                                          color: d.tipo === 'Error' ? '#e55353' : '#00802b',
                                        }}
                                      >
                                        <td style={{ textAlign: 'left' }}>{d.nome}</td>
                                        {/* <td>{d.subgrupo}</td>
                                        <td>{d.nrLote}</td> */}
                                        <td>
                                          <div dangerouslySetInnerHTML={{ __html: d.mensagem.replace('|', ' | ') }} />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Col>
                              <Col md={12} className="btn_itens text-right" style={{ margin: 'auto' }}>
                                <Button type="button" onClick={() => setMsg({ id: 1, message: '' })} className="btn-wide mr-2 btn-lg" color="danger">
                                  Limpar dados
                                </Button>
                              </Col>
                            </Fragment>
                          )}
                        </Col>
                      ),
                    }[msg.id]
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default FormConsulta
