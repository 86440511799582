import React, { Fragment, useState, useEffect } from 'react'
import { ReactCSSTransitionGroup, toast, Bounce, Loading } from '../../../Components'
import { Row, Col, Card, CardBody, CardHeader, Table } from 'reactstrap'
import { OrdemSendSAP, OrdensGetWithError, SysLogGetByService } from '../../../Service/ApiService'
import moment from 'moment'
import { ServiceIcon } from './styles'
import { BsArrowRepeat } from 'react-icons/bs'

export default function FormCadastro() {
  const [services, setServices] = useState()
  const [orders, setOrders] = useState()
  const [isLoading, setIsLoading] = useState(false)

  async function loadOrders() {
    const response = await OrdensGetWithError()
    if (response) setOrders(response)
  }

  async function loadServices() {
    const response = await SysLogGetByService()
    if (response) setServices(response)
  }

  async function onSendSAP(ordemId) {
    setIsLoading(true)
    const response = await OrdemSendSAP(ordemId)
    if (response) toast('Pedido enviado com sucesso', { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    else toast('Pedido não enviado - HttpClient sem sucesso', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

    await loadOrders()

    setIsLoading(false)
  }

  useEffect(() => {
    async function load() {
      await loadOrders()
      await loadServices()
    }
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <Row>
          <Col md={6}>
            <Card className="main-card mb-2">
              <CardHeader>Pedidos</CardHeader>
              <CardBody>
                <Row>
                  {orders && (
                    <Col className="tb-report position-relative table-responsive">
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Nr Pedido </th>
                            <th>Dh Pedido</th>
                            <th>Dh Log</th>
                            <th>Descrição</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((d) => (
                            <tr key={d.ordemId}>
                              <td>{d.ordemId}</td>
                              <td>{d.dhCadastro}</td>
                              <td>{d.dhLog}</td>
                              <td>{d.descricao}</td>
                              <td>
                                <button className="btn btn-info btn-sm" onClick={() => onSendSAP(d.ordemId)}>
                                  <BsArrowRepeat size={14} />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="main-card mb-2">
              <CardHeader>Serviços</CardHeader>
              <CardBody>
                <Row>
                  {services && (
                    <Col className="tb-report position-relative table-responsive">
                      <Table striped>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Processo </th>
                            <th>Início</th>
                            <th>Término</th>
                            <th>Tempo</th>
                            <th>Observação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {services.map((d) => (
                            <tr key={d.sysLogId}>
                              <td>
                                <ServiceIcon Error={d.tipo === 'ERROR'}></ServiceIcon>
                              </td>
                              <td>{d.subProcesso}</td>
                              <td>{moment(d.data).format('DD/MM HH:mm:ss')}</td>
                              <td>{moment(d.dhTermino).format('DD/MM HH:mm:ss')}</td>
                              <td>{moment(d.dhTermino).diff(moment(d.data), 'minutes')}m</td>
                              <td>{d.descricao}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
