import React, { useState, useEffect } from 'react'
import { ReactCSSTransitionGroup, i18n, PageTitle, InputCheck, Loading, MsgSemRegistros } from '../../../Components'
import { Button, Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap'
import { ContratoGet } from '../../../Service/ApiService'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import Item from './Item'
import { getUser } from '../../../Reducers/storageApp'

const initValues = { numeroOV: '', isEncerrado: false }

function FormConsulta() {
  const user = getUser()
  const [dataGrid, setDataGrid] = useState()
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(initValues)

  async function loadData() {
    setIsLoading(true)
    try {
      var _filters = { ...values }
      if (!values.contaId) {
        setValues({ ...values, contaId: user.contaId })
        _filters.contaId = user.contaId
      }

      const response = await ContratoGet(_filters)
      setDataGrid(response)
    } catch {
      setDataGrid([])
    }
    setIsLoading(false)
  }
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  return (
    <>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          {user?.tpVenda === 'I' ? (
            <>
              <PageTitle heading={i18n.t('contract.query.heading')} subheading={i18n.t('contract.query.subheading')} icon="BsFillBriefcaseFill" />
              <div className="page-content">
                <Card className="main-card mb-1">
                  <CardBody>
                    <MsgSemRegistros message="Contratos não está disponível para distribuidores" />
                  </CardBody>
                </Card>
              </div>
            </>
          ) : (
            <>
              <PageTitle heading={i18n.t('contract.query.heading')} subheading={i18n.t('contract.query.subheading')} icon="BsFillBriefcaseFill">
                <Button
                  type="button"
                  onClick={() => {
                    window.location.href = `/#/admin/Contract/OrderAdd`
                  }}
                  className="btn-pill"
                  color="success"
                  disabled={dataGrid?.length === 0 ? true : false}
                >
                  <BsPlus size={18} />
                  <span style={{ marginRight: '5px' }}> Add Pedidos</span>
                </Button>
              </PageTitle>
              <div className="page-content">
                <Card className="main-card mb-1">
                  <CardHeader>
                    Filtros
                    <Button
                      type="button"
                      onClick={() => {
                        loadData()
                        setIsOpenFilter(false)
                      }}
                      className="btn-pill"
                      color="primary"
                      id="btnPartPesquisar"
                      style={{ marginLeft: 'auto' }}
                    >
                      <BsSearch size={18} /> Buscar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setIsOpenFilter(!isOpenFilter)
                      }}
                      className="btn-transition btn-outline-primary btn-sm"
                      color="white"
                      id="btnPartPesquisar"
                      style={{ marginLeft: '10px' }}
                    >
                      {isOpenFilter ? <MdExpandLess size={22} /> : <MdExpandMore size={22} />}
                    </Button>
                  </CardHeader>
                  <Collapse isOpen={isOpenFilter}>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <FormGroup>
                            <Label>Número</Label>
                            <Input type="text" onChange={onChange} value={values.numeroOV} name="numeroOV" />
                          </FormGroup>
                        </Col>

                        <Col md={3} style={{ display: 'flex' }}>
                          <InputCheck label="Contratos encerrados" onChange={onChange} value={values.isEncerrado} name="isEncerrado" />
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
                {dataGrid?.length === 0 ? (
                  <Card className="main-card">
                    <CardBody>
                      <MsgSemRegistros message={'Nenhum contrato encontrado'} />
                    </CardBody>
                  </Card>
                ) : (
                  dataGrid?.map((d) => <Item key={d.contratoId} value={d} />)
                )}
              </div>
            </>
          )}
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

export default FormConsulta
