import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button, CustomInput, CardTitle } from 'reactstrap'
import InputFuncao from '../InputFuncao'

import { PerfilAddOrUpdate, PerfilGetFuncoes } from '../../../Service/ApiService'
import { PageTitle, ReactCSSTransitionGroup, useNavigate, toast, Bounce, i18n } from '../../../Components'

const initValues = {
  perfilId: 0,
  nome: '',
  ativo: 'true',
}

export default function FormCadastro() {
  const navigate = useNavigate()
  const [values, setValues] = useState(initValues)
  const [acessos, setAcessos] = useState()
  const [funcoes, setFuncoes] = useState()

  async function loadAcessos() {
    const response = await PerfilGetFuncoes(0)
    setFuncoes(response)
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.nome) {
      toast(i18n.t('profile.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    values.perfilId = parseInt(values.perfilId)
    values.acessos = []
    if (acessos) {
      var contentKeys = Object.keys(acessos)
      contentKeys.forEach((t) => {
        values.acessos.push({ sysFuncaoId: t, isCheck: acessos[t] })
      })
    }

    const response = await PerfilAddOrUpdate(values)
    if (response.result) {
      window.location.href = '/#/admin/Profile/Query'
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  useEffect(() => {
    loadAcessos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSelFuncao(ev) {
    const { name, value } = ev.target
    setAcessos({ ...acessos, [name]: value })
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('profile.add.heading')} subheading={i18n.t('profile.add.subheading')} />
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={10}>
                    <FormGroup>
                      <Label>{i18n.t('profile.add.form.name')} </Label>
                      <Input type="text" onChange={onChange} name="nome" id="nome" />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <Label>{i18n.t('profile.add.form.ative.title')}</Label>
                    <CustomInput type="select" onChange={onChange} id="ativo" name="ativo">
                      <option value=""></option>
                      <option value="true">{i18n.t('profile.add.form.ative.true')}</option>
                      <option value="false">{i18n.t('profile.add.form.ative.false')}</option>
                    </CustomInput>
                  </Col>

                  {funcoes &&
                    funcoes.map((funcao) => (
                      <Col md={3} key={funcao.grupo}>
                        <Card body className="card-shadow-primary border mb-3" outline color="primary">
                          <CardTitle>{funcao.grupo}</CardTitle>

                          {funcao.itens.map((item) => (
                            <InputFuncao key={item.sysFuncaoId} value={item.perAcessoId !== 0} name={item.sysFuncaoId} label={item.nome} onChange={onSelFuncao} />
                          ))}
                        </Card>
                      </Col>
                    ))}

                  <Col md={12} className="d-block text-right">
                    <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                      {i18n.t('layout.btnGoBack')}
                    </Button>

                    <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                      {i18n.t('profile.add.form.buttonSave')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
