import React from 'react'
import { ReactCSSTransitionGroup, i18n } from '../../../../Components'
import { BsEyeFill } from 'react-icons/bs'
import { Badge, Card, CardBody, CardHeader, Col, Table } from 'reactstrap'

export default function Componente({ records }) {
  const getBadge = (situacao) => {
    switch (situacao) {
      case '10':
        return 'warning'

      case '20':
        return 'focus'
      case '30':
        return 'focus'
      case '40':
        return 'focus'
      case '90':
        return 'success'

      case '99':
        return 'dark'
      default:
        return 'danger'
    }
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <Card className="mb-2">
        <CardHeader className="card-header-tab">PEDIDOS RECENTES DE CLIENTES</CardHeader>
        <CardBody className="pt-0">
          <Col className="tb-report position-relative table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th>{i18n.t('home.order.columns.orderId')} </th>
                  <th>{i18n.t('home.order.columns.date')}</th>
                  <th>{i18n.t('home.order.columns.customer')}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t('home.order.columns.status')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {records.map((d) => (
                  <tr key={d.ordemId}>
                    <td>{d.ordemIdExt}</td>
                    <td>{d.dhCadastro}</td>
                    <td>{d.nmParticipante}</td>

                    <td style={{ textAlign: 'center' }}>
                      <Badge className="btn-block mb-2 mr-2 badge" color={getBadge(d.situacao)}>
                        {d.nmSituacao}
                      </Badge>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <button
                        className="btn btn-outline-primary btn-square btn-sm"
                        onClick={() => {
                          window.location.href = '/#/Admin/Order/Details/' + d.ordemId
                        }}
                      >
                        <BsEyeFill />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </CardBody>
      </Card>
    </ReactCSSTransitionGroup>
  )
}
