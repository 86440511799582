import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

function InputComponent({ value, onChange, ...inputProps }) {
  const [state, setstate] = useState(value)

  useEffect(() => {
    if (value == undefined || value === 0) setstate('')
    else setstate(Mask(value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })))
  }, [value])

  const onInput = (ev) => {
    var valMasked = Mask(ev.target.value)
    setstate(valMasked)
    if (onChange) {
      onChange({
        target: {
          value: valMasked?.replace('.', '').replace(',', '.'),
          ...inputProps,
        },
      })
    }
  }

  const Mask = (value) => {
    if (value === undefined || value === 0) return

    var tmp = value
      .toString()
      .replace(/\D/g, '')
      .replace(/([0-9]{2})$/g, ',$1')
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')

    return tmp
  }

  return <Input onChange={onInput} type="text" value={state} {...inputProps} />
}

export default InputComponent
