import React, { Fragment } from 'react'
import { BsCloudDownload } from 'react-icons/bs'

import { CardTitle, Col, Table } from 'reactstrap'
import { MaskReal } from '../../../helpers/functions'
//import { i18n } from '../../../Components'

export default function Element({ titulos }) {
  async function onOpen(id) {
    window.location.href = `${process.env.REACT_APP_API}/SysArquivo/GetSysArquivo?id=${id}`
  }
  return (
    <Fragment>
      <CardTitle>
        <span>Histórico financeiro - TÍtulos</span>
      </CardTitle>
      {titulos?.length > 0 && (
        <Fragment>
          <Col className="tb-report position-relative table-responsive">
            <Table striped className="mb-4">
              <thead>
                <tr>
                  <th>Remessa</th>
                  <th style={{ textAlign: 'right' }}>Valor</th>
                  <th style={{ textAlign: 'center' }}>Vencimento</th>
                  <th style={{ textAlign: 'center' }}>Nosso Número</th>
                  <th>Documento</th>
                </tr>
              </thead>
              <tbody>
                {titulos.map((d) => (
                  <tr
                    key={d.tituloId}
                    style={{
                      color: d.diasAberto !== '' ? '#e55353' : '#3399ff',
                    }}
                  >
                    <td>{d.ordRemessaIdExt}</td>
                    <td style={{ textAlign: 'right' }}>{d.vlDocumento != null ? MaskReal(d.vlDocumento) : ''}</td>
                    <td style={{ textAlign: 'center' }}>{d.dtVencimento}</td>
                    <td style={{ textAlign: 'center' }}>{d.nossoNr}</td>
                    <td style={{ textAlign: 'center' }}>
                      {d.sysArquivo && (
                        <button className="btn btn-info btn-sm" onClick={() => onOpen(d.sysArquivo.sysArquivoId)}>
                          <BsCloudDownload size={14} />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Fragment>
      )}
    </Fragment>
  )
}
