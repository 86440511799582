import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { ReactCSSTransitionGroup, ApexCharts } from '../../../../Components'
import { MaskReal, abbreviateNumber } from '../../../../helpers/functions'

export default function FormCadastro({ records }) {
  let chartEvolucao = {
    series: records.series,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '65%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: records.labels,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return abbreviateNumber(value)
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return MaskReal(val)
          },
        },
      },
    },
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <Card className="mb-2">
        <CardHeader className="card-header-tab">Volume financeiro por cliente</CardHeader>
        <CardBody className="pt-0">
          <ApexCharts options={chartEvolucao.options} series={chartEvolucao.series} type="bar" height={350} />
        </CardBody>
      </Card>
    </ReactCSSTransitionGroup>
  )
}
