import React, { Fragment, useEffect } from 'react'
import { useDispatch, i18n } from '../index'
import { UsuarioGetAcessos } from '../../Service/ApiService'

export default function Element({ onChange }) {
  const dispatch = useDispatch()

  async function User_LoadMenu() {
    const acessos = await UsuarioGetAcessos()

    let menu = []
    if (acessos) {
      menu.push({ icon: 'bi bi-grid-fill', label: i18n.t('layout.menu.home'), to: '/#/admin' })

      acessos.forEach((itemG) => {
        if (itemG.grupo === 'Documentos') {
          menu.push({ icon: 'bi bi-folder2', label: i18n.t('layout.menu.document'), to: '/#/admin/Document/Query' })
        } else if (itemG.grupo === 'Pedidos') {
          menu.push({ icon: 'bi bi-cart-fill', label: i18n.t('layout.menu.order'), to: '/#/admin/Order/Query' })
        } else if (itemG.grupo === 'Títulos') {
          menu.push({ icon: 'bi bi-currency-dollar', label: 'Títulos em aberto', to: '/#/admin/Financial' })
        } else if (itemG.grupo === 'Contratos') {
          menu.push({ icon: 'bi bi-briefcase-fill', label: 'Contratos', to: '/#/admin/Contract/Query' })
        } else if (itemG.grupo === 'Configurações') {
          let menuGrupo = []
          itemG.itens.forEach((itemM) => {
            if (itemM.codigo === 'Perfil') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.profile'), to: '/#/admin/Profile/Query' })
            } else if (itemM.codigo === 'Usuario') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.user'), to: '/#/Admin/User/Query' })
            } else if (itemM.codigo === 'TpOrdem') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.tpOrder'), to: '/#/Admin/TpOrder/Query' })
            } else if (itemM.codigo === 'ContaCon') {
              menuGrupo.push({ label: i18n.t('layout.menu.account'), to: '/#/Admin/Account/Query' })
            } else if (itemM.codigo === 'LoteUpload') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.lotUpload'), to: '/#/Admin/LotUpload' })
            } else if (itemM.codigo === 'BomUpload') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.bomUpload'), to: '/#/Admin/bomUpload' })
            } else if (itemM.codigo === 'Parameters') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.Parameters'), to: '/#/Admin/Parameters' })
            } else if (itemM.codigo === 'SysMonitor') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.SysMonitor'), to: '/#/Admin/SysMonitor' })
            } else if (itemM.codigo === 'Segment') {
              menuGrupo.push({ label: i18n.t('layout.menu.settings.segments'), to: '/#/Admin/Segment/Query' })
            }
          })

          if (menuGrupo.length > 0) {
            menu.push({
              icon: 'fa fa-cog',
              label: i18n.t('layout.menu.settings.name'),
              content: menuGrupo,
            })
          }
        }
      })

      dispatch({ type: 'Par_Acessos', value: acessos })
    }
    dispatch({ type: 'Par_Menu', value: menu })

    onChange()
  }

  useEffect(() => {
    User_LoadMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Fragment></Fragment>
}
