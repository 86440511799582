import styled from 'styled-components'

export const BtnWhapper = styled.div`
  display: flex;
`

export const BtnFolder = styled.span`
  font-size: 14px;
  font-weight: 600;
  padding: 0 5px;
  :hover {
    background-color: var(--secondary);
    cursor: pointer;
    border: 2px solid var(--gray);
    border-radius: 4px;
  }
`
