import { Card, CardTitle } from 'reactstrap'
import styled from 'styled-components'

export const CCard = styled(Card)`
  display: flex;
  flex-direction: row;
`

export const StatusBand = styled.div`
  width: 10px;
  background: ${(props) => props.color && props.color};

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`
export const CCardTitle = styled(CardTitle)`
  align-items: center;
  border-color: #033c73;
  span {
    margin-left: 5px;
  }

  [type='button'] {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    margin-left: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
