import React, { Fragment, useState, useEffect } from 'react'
import { Button, Card, CardBody, Col } from 'reactstrap'

import { UsuarioGet } from '../../../../Service/ApiService'
import { BsPencil, BsPlus } from 'react-icons/bs'
import ModalUser from './Modal'

import { ReactCSSTransitionGroup, i18n, ReactDatatable, useParams } from '../../../../Components'

export default function FormUsuario() {
  const { id } = useParams()
  const [DataGrid, setDataGrid] = useState()
  const [confModal, setConfModal] = useState({ showModal: false, usuarioId: undefined, contaId: id })

  const columns = [
    { key: 'nome', text: i18n.t('account.edit.users.columns.name'), sortable: true },
    { key: 'email', text: i18n.t('account.edit.users.columns.email'), align: 'left', sortable: true },
    { key: 'ativo', text: i18n.t('account.edit.users.columns.status'), align: 'left', sortable: true },
    {
      key: 'action',
      text: '#',
      className: 'action',
      width: 40,
      align: 'left',
      sortable: false,
      cell: (record) => {
        return (
          <button className="btn btn-primary btn-sm" onClick={() => setConfModal({ ...confModal, showModal: true, usuarioId: record.usuarioId })}>
            <BsPencil />
          </button>
        )
      },
    },
  ]
  const config = {
    key_column: 'usuarioId',
    page_size: 20,
    length_menu: [10, 20, 50],
    show_filter: false,
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadGrid() {
    const response = await UsuarioGet({ contaId: id })
    setDataGrid(response)
  }

  useEffect(() => {
    loadGrid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onModal() {
    setConfModal({ ...confModal, showModal: !confModal.showModal })
    loadGrid()
  }

  return (
    <Fragment>
      <ModalUser onChange={onModal} showModal={confModal.showModal} usuarioId={confModal.usuarioId} contaId={confModal.contaId} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <Card className="main-card mb-2">
          <CardBody>
            <Col md={12} className="d-block text-right">
              <Button type="button" onClick={() => setConfModal({ ...confModal, showModal: true, usuarioId: undefined })} className="btn-pill" color="success" style={{ marginRight: '15px', zIndex: '1' }}>
                <BsPlus size={18} />
              </Button>
            </Col>
            <Col md={12} style={{ marginTop: '-30px' }}>
              <ReactDatatable config={config} records={DataGrid} columns={columns} />
            </Col>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
