import React, { useState, useEffect } from 'react'
import { DropdownMenu, UncontrolledButtonDropdown, DropdownItem } from 'reactstrap'
import { CgShapeCircle } from 'react-icons/cg'
import { BoxButton, CDropdownItem, CDropdownToggle, CIcon } from './styles'
import { i18n } from '../../../translate/i18n'
import { SysLogGetNotifications, SysLogReadByUsuario } from '../../../Service/ApiService'

export default function UserBox() {
  const [values, setValues] = useState()
  const [qtde, setQtde] = useState(values?.length)

  async function limparNotificacao() {
    if (qtde > 0) {
      await SysLogReadByUsuario()
      setQtde(0)
    }
  }

  function getColor(tipo) {
    if (tipo === 'ERROR') return 'red'
    else if (tipo === 'SUCCESS' || tipo === 'OK' || tipo === 'INFO') return 'green'
    else if (tipo === 'Warning') return 'orange'
  }
  function getColorB(tipo) {
    if (tipo === 'ERROR') return '#fce9e9'
    else if (tipo === 'SUCCESS' || tipo === 'OK' || tipo === 'INFO') return '#eafaee'
    else if (tipo === 'Warning') return '#ffefe6'
  }

  async function loadValues() {
    var response = await SysLogGetNotifications()
    setValues(response)

    const naoLidos = response?.filter((f) => f.statusView !== 2)
    setQtde(naoLidos?.length)
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <UncontrolledButtonDropdown>
        <CDropdownToggle color="link" className="rounded-circle-Dropdown" onClick={() => limparNotificacao()}>
          <BoxButton>
            <span className="badge rounded-pill badge-notification bg-danger position-absolute text-white" style={{ marginLeft: '12px', fontSize: '10px' }}>
              {qtde > 0 ? qtde : ''}
            </span>
            <CIcon size={30} qtde={qtde} />
          </BoxButton>
        </CDropdownToggle>
        <DropdownMenu right className="rm-pointers dropdown-menu">
          <DropdownItem className="text-center" header>
            <span style={{ fontSize: '18px' }} className="font-weight-bold m-0">
              {i18n.t('layout.header.Notification.title')}
            </span>
            {qtde > 0 && (
              <span style={{ fontSize: '12px' }}>
                {i18n.t('layout.header.Notification.body')} {qtde}
              </span>
            )}
          </DropdownItem>
          <DropdownItem divider />
          {values?.length > 0 ? (
            values.map((d) => (
              <CDropdownItem key={d.sysLogId} className="px-2" color={getColorB(d.tipo)}>
                <span className="text-muted font-weight-bold" style={{ fontSize: '15px' }}>
                  <CgShapeCircle color={getColor(d.tipo)} size={20} />
                  {d.processo} - {d.subProcesso}
                </span>

                <br />
                <span className="p-4" style={{ fontSize: '12px' }}>
                  {d.descricao}
                </span>
              </CDropdownItem>
            ))
          ) : (
            <CDropdownItem className="px-2">
              <span className="p-4" style={{ fontSize: '14px' }}>
                {i18n.t('layout.header.Notification.noRecords')}
              </span>
            </CDropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </>
  )
}
