import React from 'react'
import { Modal, ModalBody, ModalHeader, Col, ModalFooter, Button } from 'reactstrap'

export default function Element({ onChange }) {
  function onModal() {
    onChange()
  }

  return (
    <Modal isOpen={true} size="lg" toggle={onModal} style={{ width: '100%' }}>
      <ModalHeader toggle={onModal}>Confirmação de envio</ModalHeader>
      <ModalBody style={{ background: '#ff914d' }}>
        <Col md={12}>
          <h4 style={{ color: 'white' }}>Simulação de preço</h4>
          <p style={{ color: 'white' }}>Recomendamos a cotação de preço antes de enviar o pedido.</p>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="btn btn-secondary btn-lg btn-wide" onClick={() => onChange(false)}>
          Enviar sem preço
        </Button>
        <Button className="btn-wide mr-2 btn-lg" color="focus" onClick={() => onChange(true)}>
          Calcular preço
        </Button>
      </ModalFooter>
    </Modal>
  )
}
