import React, { Fragment, useEffect, useState } from 'react'
import { Loading, PageTitle, ReactCSSTransitionGroup, i18n } from '../../../Components'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import Vendas from './Vendas'
import Grupos from './Grupos'
import Ordens from './Ordens'
import CardFolders from '../Folders'
import { getUser } from '../../../Reducers/storageApp'
import { OrdemGetDashExecutivo } from '../../../Service/ApiService'

export default function Componente() {
  const { contaId } = getUser()
  const [records, setRecords] = useState()
  const [isLoading, setIsLoading] = useState(false)

  async function loadData() {
    try {
      setIsLoading(true)
      var response = await OrdemGetDashExecutivo()
      setRecords(response)
    } catch {}

    setIsLoading(false)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('home.heading')} subheading={i18n.t('home.admin.subheading')} icon="BsGridFill" />
          <div className="page-content">
            {contaId == '' && <li className="list-group-item-danger list-group-item mb-2">Selecione uma conta para habilitar mais funcionalidades</li>}

            {records && !isLoading && (
              <Row>
                <Col md="6">
                  <Ordens records={records.ordens} />

                  <Card className="main-card mb-2">
                    <CardBody>
                      <CardTitle>{i18n.t('home.files.title')}</CardTitle>
                      <CardFolders />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Vendas records={records?.vendas} />

                  <Grupos records={records?.grupos} />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
