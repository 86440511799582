import { DropdownToggle } from 'reactstrap'
import styled, { keyframes } from 'styled-components'
import { IoIosNotifications } from 'react-icons/io'

const breatheAnimation = keyframes`
 0% { transform: scale(.7) }
 70% { 
    transform: scale(1.1);
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
 }
 100% { 
    transform: scale(.7);
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0); 
 } 
`

export const CDropdownToggle = styled(DropdownToggle)``
export const BoxButton = styled.div`
  border-radius: 50%;
  background-color: #f4beca !important;
  transition: opacity 0.2s;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CIcon = styled(IoIosNotifications)`
  animation-name: ${breatheAnimation};
  animation-duration: 1.5s;

  animation-iteration-count: ${(props) => (props.qtde ? 'infinite' : 'unset')}; ;
`
export const CDropdownItem = styled.div`
  //cursor: pointer;
  padding: 0.4rem 0.5rem !important;
  background-color: ${(props) => props.color && props.color};
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  margin-bottom: 2px;
  :hover {
    background: var(--secondary);
  }
`
