import React, { useState, Fragment, useEffect } from 'react'
import { toast, Bounce, i18n, Loading } from '../../../Components'

import { GetTokenApp, RecuperarSenha } from '../../../Service/ApiService'
import { ContainerForm, Input, Button, Link, FooterLogin } from '../styles'

export default function Element() {
  const [values, setValues] = useState({ email: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [TokenApp, setTokenApp] = useState()
  useEffect(() => {
    getTokenApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getTokenApp() {
    if (!TokenApp) {
      var response = await GetTokenApp()
      if (response.token) {
        setTokenApp(response.token)
      } else {
        toast(i18n.t('login.recover.MSG_ErrorGetTokenApp'), { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    }
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  function onSubmit(ev) {
    ev.preventDefault()

    if (values.email.length < 6) {
      toast(i18n.t('login.recover.MSG_ValidateEmail'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    getTokenApp()
    Recuperar(values.email)
  }

  async function Recuperar(email) {
    try {
      setIsLoading(true)
      const req = await RecuperarSenha(TokenApp, email)
      if (req.result) {
        toast(i18n.t('login.recover.MSG_SuccessSend'), { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

        setTimeout(function () {
          window.location.href = '/#/Login'
        }, 5000)
      } else {
        toast(req.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    } catch (error) {
      toast(`${error}`, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
    setIsLoading(false)
  }

  return (
    <Fragment>
      <Loading isVisible={isLoading} />
      <ContainerForm>
        <Input type="email" name="email" placeholder={i18n.t('login.recover.email')} onChange={onChange} value={values.email} />
        <Button onClick={onSubmit}> {i18n.t('login.recover.buttonSubmit')} </Button>

        <FooterLogin>
          <Link href="/#/Login">{i18n.t('login.recover.buttonSingIn')}</Link>
        </FooterLogin>
      </ContainerForm>
    </Fragment>
  )
}
