import React, { Fragment, useEffect, useState } from 'react'

import { Card, CardBody, Row, Col, FormGroup, Label, Button, CardTitle, Table, CustomInput } from 'reactstrap'
import { InputNumber, i18n } from '../../Components'
import { BomGet, BomGetAddons, BomGetOptionals } from '../../Service/ApiService'
import { BsPlusLg, BsDashLg } from 'react-icons/bs'
import AsyncSelect from 'react-select/async'

const initValues = {
  materialId: '',
  materialIdExt: '',
  nmMaterial: '',
  qtde: '1',
  voltagem: '',
  index: '',
}

export default function Element({ onChange, tpOrdemId }) {
  const [values, setValues] = useState(initValues)
  const [boms, setBoms] = useState()
  const [addons, setAddons] = useState([])
  const [optionals, setOptionals] = useState([])

  function onChangeI(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  async function onChangeBom(ev) {
    if (ev) {
      setValues({ ...values, bom: { ...ev } })
      comporItem(undefined, undefined, ev)
      await onLoadAddons(ev.value)
      onLoadOptionals(ev.value)
    } else {
      setValues({ ...values, bom: '' })
      setAddons([])
      setOptionals([])
    }
  }
  useEffect(() => {
    if (tpOrdemId) {
      onLoadBom()
      setValues({ ...initValues })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tpOrdemId])

  async function onLoadBom() {
    let items = []
    var result = await BomGet({ tipo: 'Configuração' })

    if (result)
      result.forEach((item) => {
        if (item.nome.length > 0) items.push({ value: item.bomId, label: item.nome, ...item })
      })

    setBoms(items)
    return items
  }

  async function onLoadAddons(bomId) {
    var result = await BomGetAddons(bomId)
    setAddons(result)
  }
  async function onLoadOptionals(bomId) {
    var result = await BomGetOptionals(bomId)
    setOptionals(result)
  }

  async function onAddons(operacao, bomId) {
    let _addons = []
    addons.forEach((item) => {
      if (item.bomId === bomId) {
        var _qtde = 0
        if (item.qtde) _qtde = item.qtde

        if (operacao === '+') _qtde += 1
        else _qtde -= 1

        if (_qtde < 0) _qtde = 0
        _addons.push({ ...item, qtde: _qtde })
      } else _addons.push(item)
    })
    setAddons(_addons)

    comporItem(_addons, optionals, values.bom)
  }
  async function onOptional(operacao, id) {
    let _optionals = []
    optionals.forEach((item) => {
      if (item.bomMaterialId === id) {
        var _qtde = 0
        if (item.qtde) _qtde = item.qtde

        if (operacao === '+') _qtde += 1
        else _qtde -= 1

        if (_qtde < 0) _qtde = 0
        _optionals.push({ ...item, qtde: _qtde })
      } else _optionals.push(item)
    })
    setOptionals(_optionals)

    comporItem(addons, _optionals)
  }

  async function comporItem(_addons, _optionals, _bom = values.bom) {
    let _itens = []

    _bom.material.forEach((mat) => {
      if (mat.voltagem === values.voltagem || mat.voltagem == null)
        _itens.push({
          materialId: mat.materialId,
          materialIdExt: mat.materialIdExt,
          nmMaterial: mat.nmMaterial,
          qtde: mat.qtde,
        })
    })

    if (_addons)
      _addons.forEach((addon) => {
        if (addon.qtde > 0) {
          addon.material.forEach((mat) => {
            if (mat.voltagem === values.voltagem || mat.voltagem == null)
              _itens.push({
                materialId: mat.materialId,
                materialIdExt: mat.materialIdExt,
                nmMaterial: mat.nmMaterial,
                qtde: mat.qtde * addon.qtde,
              })
          })
        }
      })

    if (_optionals)
      _optionals.forEach((item) => {
        if (item.qtde > 0) {
          _itens.push({
            materialId: item.materialId,
            materialIdExt: item.materialIdExt,
            nmMaterial: item.nmMaterial,
            qtde: item.qtde,
          })
        }
      })

    onChange(_itens)
  }

  return (
    <Fragment>
      <Card className="main-card mb-2">
        <CardBody>
          <CardTitle style={{ display: 'flex' }}>
            <span>Instrumento</span>
          </CardTitle>
          <Row>
            <Col md={2}>
              <Label>Voltagem *</Label>
              <CustomInput type="select" onChange={onChangeI} id="voltagem" name="voltagem">
                <option value=""></option>
                <option value="100v">100v</option>
                <option value="200v">200v</option>
              </CustomInput>
            </Col>
            <Col md={9}>
              <FormGroup>
                <Label>Instrumento *</Label>
                <AsyncSelect
                  placeholder="Digite para pesquisar"
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                  name="material"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  loadOptions={onLoadBom}
                  onChange={onChangeBom}
                  value={values.bom}
                  defaultOptions={boms}
                  isClearable
                  isDisabled={!values.voltagem}
                />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label>{i18n.t('order.itens.form.qtde')} *</Label>
                <InputNumber value={values.qtde} name="qtde" id="qtde" disabled={true} />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row>
        <Col md={4}>
          <Card className="main-card mb-2">
            <CardBody>
              <CardTitle style={{ display: 'flex' }}>
                <span>Módulos adicionais</span>
              </CardTitle>
              <Row>
                <Col className="tb-report position-relative table-responsive">
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {addons.map((d) => (
                        <tr key={d.bomId}>
                          <td>{d.nome}</td>
                          <td style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                              <Button
                                className="btn btn-secondary btn-sm"
                                onClick={() => {
                                  onAddons('-', d.bomId)
                                }}
                              >
                                <BsDashLg />
                              </Button>
                              <div style={{ width: '30px', background: '#fff', height: '100%', textAlign: 'center' }}> {d.qtde ? d.qtde : 0} </div>
                              <Button
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                  onAddons('+', d.bomId)
                                }}
                              >
                                <BsPlusLg />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={8}>
          <Card className="main-card mb-2">
            <CardBody>
              <CardTitle style={{ display: 'flex' }}>
                <span>Outros componentes/Itens opcionais</span>
              </CardTitle>
              <Row>
                <Col className="tb-report position-relative table-responsive">
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th>Observação</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {optionals.map((d) => (
                        <tr key={d.bomMaterialId}>
                          <td>{d.materialIdExt}</td>
                          <td>{d.nmMaterial}</td>
                          <td>{d.observacao}</td>

                          <td style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                              <Button
                                className="btn btn-secondary btn-sm"
                                onClick={() => {
                                  onOptional('-', d.bomMaterialId)
                                }}
                              >
                                <BsDashLg />
                              </Button>
                              <div style={{ width: '30px', background: '#fff', height: '100%', textAlign: 'center' }}> {d.qtde ? d.qtde : 0} </div>
                              <Button
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                  onOptional('+', d.bomMaterialId)
                                }}
                              >
                                <BsPlusLg />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}
