import React, { useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button, CustomInput, Alert } from 'reactstrap'

import { TpOrdemAddOrUpdate } from '../../../Service/ApiService'
import { PageTitle, ReactCSSTransitionGroup, useNavigate, toast, Bounce, i18n } from '../../../Components'

const initValues = {
  tpOrdemId: 0,
  tpOrdemIdExt: '',
  ativo: '',
  nome: '',
  matGrupos: [],
  materiais: [],
  segmentos: [],
  obrigaLote: false,
  nrDiasPrevEntrega: '',
  diaSemana: '',
  diasAntecedencia: '',
  nmDiasAntecedencia: '',
  qtdeOrdemCIF: '',
  isBillOfMaterial: false,
  tpCalcEntrega: '1',
  subtipo: '',
}

export default function FormCadastro() {
  const navigate = useNavigate()
  const [values, setValues] = useState(initValues)

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.nome || !values.tpOrdemIdExt || !values.ativo) {
      toast(i18n.t('tpOrder.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    let _values = { ...values }
    _values.matGrupos = []
    if (_values.matGrupos) {
      values.matGrupos.forEach((element) => {
        _values.matGrupos.push(element.value)
      })
    }

    _values.segmentos = []
    if (values.segmentos) {
      values.segmentos.forEach((element) => {
        _values.segmentos.push(element.value)
      })
    }

    _values.materiais = []
    if (values.materiais) {
      values.materiais.forEach((element) => {
        _values.materiais.push(element.value)
      })
    }

    const response = await TpOrdemAddOrUpdate(_values)
    if (response.result) {
      window.location.href = `/#/admin/tpOrder/Edit/${response.id}`
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <div className="body-tabs body-tabs-layout">
        <PageTitle heading={i18n.t('tpOrder.add.heading')} subheading={i18n.t('tpOrder.add.subheading')} />
        <div className="page-content">
          <Card className="main-card mb-2">
            <CardBody>
              <Row>
                <Col md={2}>
                  <FormGroup>
                    <Label>{i18n.t('tpOrder.add.form.codeExt')} *</Label>
                    <Input type="text" onChange={onChange} name="tpOrdemIdExt" id="tpOrdemIdExt" />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Label>{i18n.t('tpOrder.add.form.name')} *</Label>
                  <Input type="text" onChange={onChange} name="nome" id="nome" />
                </Col>
                <Col md={2}>
                  <Label>Subtipo *</Label>
                  <CustomInput type="select" onChange={onChange} id="subtipo" name="subtipo" value={values.subtipo}>
                    <option value=""></option>
                    <option value="OT">Outros</option>
                    <option value="CB">Contrato Bonificação</option>
                    <option value="CN">Contrato Normal</option>
                  </CustomInput>
                </Col>
                <Col md={2}>
                  <Label>{i18n.t('tpOrder.add.form.ative.title')}</Label>
                  <CustomInput type="select" onChange={onChange} id="ativo" name="ativo">
                    <option value=""></option>
                    <option value="true">{i18n.t('tpOrder.add.form.ative.true')}</option>
                    <option value="false">{i18n.t('tpOrder.add.form.ative.false')}</option>
                  </CustomInput>
                </Col>

                <Col md={12} className="text-center mt-2">
                  <Alert color="warning">Preencha os campos acima e click em salvar para habilitar todas as configurações.</Alert>
                </Col>

                <Col md={12} className="d-block text-right">
                  <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                    {i18n.t('layout.btnGoBack')}
                  </Button>
                  <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                    {i18n.t('tpOrder.add.form.buttonSave')}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </ReactCSSTransitionGroup>
  )
}
