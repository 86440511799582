import { Fragment, React } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AppHeader from '../AppHeader'
import AppSidebar from '../AppSidebar'
import AppFooter from '../AppFooter'

import Home from '../../Pages/Home/index'

import AccountAdd from '../../Pages/Account/Add'
import AccountQuery from '../../Pages/Account/Query'
import AccountEdit from '../../Pages/Account/Edit'

import UserAdd from '../../Pages/User/Add'
import UserQuery from '../../Pages/User/Query'
import UserEdit from '../../Pages/User/Edit'
import UserProfile from '../../Pages/User/Profile'

import ProfileAdd from '../../Pages/Profile/Add'
import ProfileQUery from '../../Pages/Profile/Query'
import ProfileEdit from '../../Pages/Profile/Edit'

import DocumentQUery from '../../Pages/Document/Query'

import OrderAdd from '../../Pages/Order/Add'
import OrderQUery from '../../Pages/Order/Query'
import OrderEdit from '../../Pages/Order/Edit'
import OrderDetails from '../../Pages/Order/Details'

import TpOrderAdd from '../../Pages/TpOrder/Add'
import TpOrderQUery from '../../Pages/TpOrder/Query'
import TpOrderEdit from '../../Pages/TpOrder/Edit'

import SegmentAdd from '../../Pages/Segment/Add'
import SegmentQuery from '../../Pages/Segment/Query'
import SegmentEdit from '../../Pages/Segment/Edit'

import Financial from '../../Pages/Financial'
import LotUpload from '../../Pages/LotUpload'
import BomUpload from '../../Pages/BomUpload'

import Contact from '../../Pages/Contact'
import Faq from '../../Pages/Faq'
import Parameters from '../../Pages/Parameters'
import SysMonitor from '../../Pages/SysMonitor'
import { NotFound } from '../../Components'

import ContractQuery from '../../Pages/Contract/Query'
import ContractOrderAdd from '../../Pages/Contract/OrderAdd'
import ContractOrderAdd2 from '../../Pages/Contract/OrderAdd2'

const Main = () => {
  const Par_Acessos = useSelector((state) => state.Par_Acessos)
  function renderComponent(item) {
    switch (item.codigo) {
      case 'ContaCon':
        return (
          <Fragment>
            <Route path="/Account/Add" element={<AccountAdd />} />
            <Route path="/Account/Query" element={<AccountQuery />} />
            <Route path="/Account/Edit/:id" element={<AccountEdit />} />
          </Fragment>
        )
      case 'Usuario':
        return (
          <Fragment>
            <Route path="/User/Add" element={<UserAdd />} />
            <Route path="/User/Query" element={<UserQuery />} />
            <Route path="/User/Edit/:id" element={<UserEdit />} />
          </Fragment>
        )
      case 'Perfil':
        return (
          <Fragment>
            <Route path="/Profile/Add" element={<ProfileAdd />} />
            <Route path="/Profile/Query" element={<ProfileQUery />} />
            <Route path="/Profile/Edit/:id" element={<ProfileEdit />} />
          </Fragment>
        )
      case 'DocumentoCon':
        return (
          <Fragment>
            <Route path="/Document/Query" element={<DocumentQUery />} />
          </Fragment>
        )
      case 'OrdemCon':
        return <Route path="/Order/Query" element={<OrderQUery />} />
      case 'OrdemCad':
        return <Route path="/Order/Add" element={<OrderAdd />} />
      case 'OrdemEdit':
        return <Route path="/Order/Edit/:id" element={<OrderEdit />} />
      case 'TpOrdem':
        return (
          <Fragment>
            <Route path="/TpOrder/Add" element={<TpOrderAdd />} />
            <Route path="/TpOrder/Query" element={<TpOrderQUery />} />
            <Route path="/TpOrder/Edit/:id" element={<TpOrderEdit />} />
          </Fragment>
        )
      case 'Segment':
        return (
          <Fragment>
            <Route path="/Segment/Add" element={<SegmentAdd />} />
            <Route path="/Segment/Query" element={<SegmentQuery />} />
            <Route path="/Segment/Edit/:id" element={<SegmentEdit />} />
          </Fragment>
        )
      case 'SysMonitor':
        return <Route path="/SysMonitor" element={<SysMonitor />} />
      case 'FinTituloCon':
        return <Route path="/Financial" element={<Financial />} />
      case 'LoteUpload':
        return <Route path="/LotUpload" element={<LotUpload />} />
      case 'BomUpload':
        return <Route path="/BomUpload" element={<BomUpload />} />
      case 'ContratoCon':
        return (
          <Fragment>
            <Route path="/Contract/Query" element={<ContractQuery />} />
            <Route path="/Contract/OrderAdd/:id" element={<ContractOrderAdd />} />
            <Route path="/Contract/OrderAdd" element={<ContractOrderAdd2 />} />
          </Fragment>
        )

      default:
        return <Fragment />
    }
  }

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Routes>
              <Route path="/" exact element={<Home />} />

              {Par_Acessos?.map((grupo, keyGrupo) => (
                <Fragment key={keyGrupo}>
                  {grupo.itens.map((item, keyItem) => (
                    <Fragment key={keyItem}>{renderComponent(item)}</Fragment>
                  ))}
                </Fragment>
              ))}

              <Route path="/Order/Details/:id" element={<OrderDetails />} />

              <Route path="/User/Profile" element={<UserProfile />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/Faq" element={<Faq />} />

              <Route path="/Parameters" element={<Parameters />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}
export default Main
