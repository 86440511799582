import React, { useState, useEffect } from 'react'
import { ReactCSSTransitionGroup, useNavigate, toast, Bounce, i18n, Select, useParams } from '../../../Components'
import { Card, CardBody, Row, Col, CustomInput, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { ContaGetById, ContaUp, SegmentoGet, UsuarioGet } from '../../../Service/ApiService'

export default function FormEdit() {
  const { id } = useParams()
  const [values, setValues] = useState()
  const navigate = useNavigate()
  const [segmentos, setSegmentos] = useState()
  const [executivos, setExecutivos] = useState()

  async function loadSegmentos(segmentos) {
    const response = await SegmentoGet({ ativo: true })
    let items = []
    let result = []
    response.forEach((item) => {
      if (segmentos.includes(item.segmentoId)) result.push({ value: item.segmentoId, label: item.nome })
      items.push({ value: item.segmentoId, label: item.nome })
    })
    setSegmentos(items)
    return result
  }

  async function loadExecutivos(executivoId) {
    const response = await UsuarioGet({ ativo: true, isExecutivo: true })
    let items = []
    let result = {}
    response.forEach((item) => {
      if (executivoId === item.usuarioId) result = { value: item.usuarioId, label: item.nome }
      items.push({ value: item.usuarioId, label: item.nome })
    })
    setExecutivos(items)
    return result
  }

  async function loadValues() {
    const response = await ContaGetById(id)

    response.segmentos = await loadSegmentos(response.segmentos)
    response.executivo = await loadExecutivos(response.executivoId)

    setValues(response)
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.nome || !values.situacao || !values.segmentos) {
      toast(i18n.t('account.edit.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    let _values = { ...values }
    _values.contaId = parseInt(_values.contaId)

    _values.segmentos = []
    if (_values.segmentos) {
      values.segmentos.forEach((element) => {
        _values.segmentos.push(element.value)
      })
    }

    _values.executivoId = values.executivo?.value
    delete _values.executivo

    const response = await ContaUp(_values)
    if (response.result === false) {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } else {
      window.location.href = '/#/admin/Account/Query'
    }
  }

  return (
    <>
      {values && (
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <Card className="main-card mb-2">
            <CardBody>
              <Form>
                <Row form>
                  <Col md={8}>
                    <FormGroup>
                      <Label>{i18n.t('account.edit.form.name')} * </Label>
                      <Input type="text" onChange={onChange} value={values.nome} name="nome" id="nome" />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <Label>{i18n.t('account.edit.form.typeSale.title')}</Label>
                    <CustomInput type="select" onChange={onChange} value={values.tpVenda} id="tpVenda" name="tpVenda">
                      <option value=""></option>
                      <option value="D">{i18n.t('account.edit.form.typeSale.direct')}</option>
                      <option value="I">{i18n.t('account.edit.form.typeSale.indirect')}</option>
                    </CustomInput>
                  </Col>
                  <Col md={2}>
                    <Label>{i18n.t('account.edit.form.status.title')} * </Label>
                    <CustomInput type="select" onChange={onChange} value={values.situacao} id="situacao" name="situacao">
                      <option value=""></option>
                      <option value="A">{i18n.t('account.add.form.status.ative')}</option>
                      <option value="N">{i18n.t('account.add.form.status.underReview')}</option>
                      <option value="I">{i18n.t('account.add.form.status.inactive')}</option>
                    </CustomInput>
                  </Col>

                  <Col md={9}>
                    <FormGroup>
                      <Label> {i18n.t('account.edit.form.segment')} *</Label>
                      <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.segmentos} isMulti isClearable name="segmentos" className="basic-multi-select" classNamePrefix="select" options={segmentos} />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label> Executivo de vendas </Label>
                      <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.executivo} isClearable name="executivo" className="basic-multi-select" classNamePrefix="select" options={executivos} />
                    </FormGroup>
                  </Col>

                  <Col md={12} className="d-block text-right">
                    <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                      {i18n.t('layout.btnGoBack')}
                    </Button>

                    <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                      {i18n.t('account.edit.form.buttonSave')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      )}
    </>
  )
}
